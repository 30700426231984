const apiURL = {

  
    get_all_chatbots:"/get_all_chatbots",
    Signin:"/Signin",
    sociallogin:"/sociallogin",
    Signup:"/Signup",
    verifyotp:"/verifyotp",
    newchangepassword:"/newchangepassword",
    forgotpassword:"/forgotpassword",
    fetch_suburls:"/fetch_suburls",
    create_chatbot:"/create_chatbot",
    update_chatbot_new:"/update_chatbot_new",
    get_chatbot:"/get_chatbot",
    get_user:"/get_user",
    payment: "/create-checkout-session",
    update_keys :"/update-keys", 
    contact:"/contact",
    get_keys:"/get_keys",
    get_urls:"/get_urls",
    update_chatbot:"/update_chatbot"

}

export default apiURL;