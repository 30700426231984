import React, { useState } from 'react'
import '../Chatbot/chatbot.css'
import FileAndTextInput from './FileAndTextInput';
import VoiceToText from './VoiceToText';
import VoiceRecorder from './VoiceRecorder';

const Chatbot = ({ chats }) => {

    const [chatContainer, setChatContainer] = useState(chats);
    const boticon = "https://sitemployee.sourcesoftsolutions.com/assets/images/chatbot/chat_icon.jpeg";
    const [audioBlob, setAudioBlob] = useState(null);

    const handleSend = (event) => {
        event.preventDefault();
        if (audioBlob) {
            // Send audioBlob to backend
            sendAudioToBackend(audioBlob);
            // Clear recorded audio
            setAudioBlob(null);
        }
    };


    const sendAudioToBackend = async (blob) => {
        const formData = new FormData();
        formData.append('audio', blob, 'audio.wav');

        try {
            const response = await fetch('/transcribe', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            console.log('Transcription:', data.transcript);
        } catch (error) {
            console.error('Error sending audio to backend:', error);
        }
    };

    return (
        <div className="chatbot-marketing">
            <div id="source-widget-container-right"
                className="source-tw-fixed source-tw-inset-0 md:source-tw-inset-auto" style={{ fontFamily: "poppins" }}>

                <div className="source-tw-bg-[white] source-tw-shadow-xl source-tw-w-full source-tw-h-full
           md:source-tw-w-[420px] md:source-tw-h-[680px]
           2xl:source-tw-w-[440px] 2xl:source-tw-h-[712px]
           xxl:source-tw-w-[600px] xxl:source-tw-h-[970px]
           md:source-tw-max-h-[80vh] md:short:source-tw-w-[360px]
           source-tw-flex source-tw-flex-col md:source-tw-rounded-2xl" style={{ display: "none" }}>
                    <div className="source-tw-py-4 source-tw-px-4 source-tw-border-b source-tw-border-gray-200 source-tw-flex source-tw-justify-between source-tw-items-center source-tw-rounded-t-none md:source-tw-rounded-t-2xl"
                        style={{ background: `linear-gradient(120deg, rgb(67, 183, 93) 100%, white)` }}>
                        <div className="source-tw-flex source-tw-items-center">
                            <h2
                                className="source-tw-font-semibold source-tw-text-xl source-tw-text-white source-tw-whitespace-pre-line">
                                <span>{"Chat"}</span>
                            </h2>
                        </div>
                        <div className="source-tw-flex source-tw-items-center source-tw-justify-end" >

                            <button className="source-tw-text-white source-tw-hover:text-gray-200 source-tw-focus:outline-none source-tw-px-2 source-tw-py-1 source-tw-rounded-full source-tw-outline-none source-tw-border-none hover:source-tw-scale-105 source-tw-ml-1"
                                style={{ backgroundColor: 'rgb(67, 183, 93)', cursor: 'pointer' }}>-
                            </button>

                            <button className="source-tw-text-white source-tw-hover:text-gray-200 source-tw-focus:outline-none source-tw-px-2 source-tw-py-1 source-tw-rounded-full source-tw-outline-none source-tw-border-none hover:source-tw-scale-105 source-tw-ml-1"
                                style={{ backgroundColor: 'rgb(67, 183, 93)', cursor: 'pointer' }}><svg stroke="currentColor"
                                    fill="currentColor" strokeWidth="0" viewBox="0 0 352 512" className="source-tw-text-lg"
                                    height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                                    </path>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="source-tw-flex source-tw-flex-col source-tw-overflow-hidden source-tw-flex-1"
                        style={{ opacity: 1 }}>
                        <div className="source-widget-content" style={{ maxHeight: '100%', overflowY: 'auto' }}>
                            <div className="-source-tw-mt-4">
                                <div className="relative group">
                                    <div className="source-tw-flex source-tw-flex-col source-tw-items-start source-tw-space-x-10 source-tw-mb-2 source-tw-mt-[10px] source-tw-text-left">
                                        <div className="source-tw-flex source-tw-items-end source-tw-space-x-3 source-tw-mb-1 source-tw-text-left source-tw-w-full">
                                            <div className="source-tw-rounded-full source-tw-overflow-hidden source-tw-w-8 source-tw-h-8.5 source-tw-flex source-tw-items-center source-tw-justify-center source-tw-flex-shrink-0" style={{ backgroundColor: 'transparent' }}>
                                                <img
                                                    src={boticon}
                                                    className="source-tw-aspect-square source-tw-flex-1 source-tw-h-full source-tw-object-cover hover:source-tw-cursor-pointer hover:source-tw-scale-110 source-tw-transition source-tw-duration-300"
                                                    alt="Agent Avatar"
                                                />
                                            </div>
                                            <div className=" source-tw-pt-3 source-tw-px-4 source-tw-rounded-xl source-tw-break-words source-tw-max-w-[80%] source-tw-bg-[#F1F1F1] source-tw-text-[#121212] source-tw-relative" style={{ paddingBottom: '10px' }}>
                                                <span>{"Welcome to Chatbot. Let me know how can I help you."}
                                                </span>
                                                <br />
                                                <div className="source-tw-buttons-container"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {chatContainer.map((chat, index) => {
                                return (
                                    chat.question != '' &&
                                    <div key={index}>
                                        <div className="source-tw-flex source-tw-items-end source-tw-justify-end source-tw-space-x-3 source-tw-mb-[17px] source-tw-text-left">
                                            <div className="source-tw-py-3 source-tw-px-4 source-tw-rounded-xl source-tw-text-white source-tw-max-w-[80%] source-tw-break-words"
                                                style={{ backgroundColor: 'rgb(67, 183, 93)' }}
                                            >
                                                <span>{chat.question}</span><br />
                                            </div>
                                        </div>

                                        <div className="-source-tw-mt-4">
                                            <div className="source-tw-message-container">
                                                <div className="source-tw-flex source-tw-flex-col source-tw-items-start source-tw-space-x-10 source-tw-mb-2 source-tw-mt-[10px] source-tw-text-left">
                                                    <div className="source-tw-flex source-tw-items-end source-tw-space-x-3 source-tw-mb-1 source-tw-text-left source-tw-w-full">
                                                        <div className="source-tw-rounded-full source-tw-overflow-hidden source-tw-w-8 source-tw-h-8.5 source-tw-flex source-tw-items-center source-tw-justify-center source-tw-flex-shrink-0" style={{ backgroundColor: 'transparent' }}>
                                                            <img src={boticon} className="source-tw-aspect-square source-tw-flex-1 source-tw-h-full source-tw-object-cover hover:source-tw-cursor-pointer hover:source-tw-scale-110 source-tw-transition source-tw-duration-300" alt="Agent Avatar" />
                                                        </div>
                                                        <div className="source-tw-pt-3 source-tw-px-4 source-tw-rounded-xl source-tw-break-words source-tw-max-w-[80%] source-tw-bg-[#F1F1F1] source-tw-text-[#121212] source-tw-relative source-tw-message-bubble " style={{ paddingBottom: '10px' }}>
                                                            <span>{chat.answer}</span>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                        <div
                            className=" source-tw-p-4 source-tw-pb-3 source-tw-border-t source-tw-border-gray-300 source-tw-bg-[white] source-tw-rounded-b-none md:source-tw-rounded-b-2xl">
                            <div
                                className="source-tw-flex source-tw-justify-center source-tw-items-center source-tw-w-full">
                                <form className="source-tw-flex source-tw-w-full source-tw-mr-1">
                                    <div className="source-tw-flex source-tw-items-center source-tw-bg-[white] source-tw-rounded-lg source-tw-w-full"
                                        style={{ borderWidth: '0.15em', borderStyle: 'solid', borderColor: 'rgb(67, 183, 93)' }}>
                                        {/* <input
                                            className="source-tw-w-full source-tw-px-4 source-tw-py-3 source-tw-rounded-lg source-tw-focus:outline-none source-tw-outline-none source-tw-border-none source-tw-text-black"
                                            type="text" placeholder="Ask me anything..." maxLength="750" value={""} />
                                        */}
                                        <FileAndTextInput />
                                        {/* <VoiceToText /> */}
                                        <VoiceRecorder setAudioBlob={setAudioBlob} />
                                        <button
                                            className="source-tw-p-3 source-tw-rounded-r-lg source-tw-focus:outline-none source-tw-outline-none source-tw-border-none hover:source-tw-scale-110"
                                            title="Send"
                                            style={{ color: 'rgb(67, 183, 93)', cursor: 'pointer', backgroundColor: 'transparent' }} onClick={handleSend} disabled={!audioBlob} >
                                            <svg
                                                stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512"
                                                height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z">
                                                </path>
                                            </svg>

                                        </button>
                                    </div>
                                </form>

                            </div>

                            <a href="https://chirpchat.ai/"
                                className="source-cursor-pointer source-tw-block source-tw-text-center source-tw-mt-2"
                                target="_blank" rel="noreferrer" style={{ color: 'rgb(119, 119, 119)' }}>
                                <p className="source-tw-text-sm source-tw-text-center">Powered by <strong> ChirpChat</strong>
                                </p>
                            </a>


                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Chatbot