import React from "react";
import { Link } from "react-router-dom";
const PrivacyPolicey = () => {
  return (
    <>

<div class="hero-basic">
  <div class="hero-basic__bg hero-basic__bg--blue">
    <div class="bg-shapes"></div>
  </div>
  <div class="hero-basic__content">
    <h1>Privacy Policies</h1>
    <p class="customizep">
    Protecting your privacy is important to us. Our Privacy Policy outlines how we collect, use, and disclose personal information through ChirpChat. Please review our Privacy Policy carefully to understand how we handle your information.
    </p>
    
  </div>
</div>


      <section className='terms'>
        <div className='container'>
          <div className='row'>
            <div className="col-md-12">
            {/* <h2 className="featuresh2 tabheading">Privacy Policies</h2> */}
            <div className="heading-container">
              {/* <h1>Protecting your privacy is important to us. Our Privacy Policy outlines how we collect, use, and disclose personal information through ChirpChat. Please review our Privacy Policy carefully to understand how we handle your information.
              </h1> */}
            </div>
              <div className='priBox'>
                <h3 className="customisedh3">Information We Collect</h3><br/>
                <p className="TandP">We collect personal information such as name, email address, and contact information when you use ChirpChat. We may also collect usage data, cookies, and other tracking technologies to improve our service.
                </p>
              </div>
              <div className='priBox'>
                <h3 className="customisedh3">How We Use Your Information</h3><br/>
                <p className="TandP">We use the information collected to provide and improve ChirpChat, personalize user experiences, and communicate with users. We do not sell or share personal information with third parties for marketing purposes..</p>

              </div>
              <div className='priBox'>
                <h3 className="customisedh3">Data Security</h3><br/>
                <p className="TandP">We implement measures to protect the security of your personal information and prevent unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.</p>

                <h3 className="customisedh3">Third-Party Links</h3><br/>
                <p className="TandP">ChirpChat may contain links to third-party websites or services that are not owned or controlled by ChirpChat. We are not responsible for the privacy practices or content of these websites.</p>
              </div>
              <div className='priBox'>
                <h3 className="customisedh3">Changes to Privacy Policy</h3><br/>
                <p className="TandP">ChirpChat reserves the right to modify or revise our Privacy Policy at any time without prior notice. Your continued use of ChirpChat constitutes acceptance of the modified policy.</p>
              </div>
            </div>
          </div>

        </div>
      </section>
      <section className="mt-4 text-center" >
        <div className="container heading-container">
          <h1>By using ChirpChat, you consent to the terms of our Privacy Policy. If you have any questions or concerns about our Privacy Policy, please contact us at info@chirpchat.ai</h1>
        </div>
      </section>
    </>
  )
}

export default PrivacyPolicey;