import React from "react";
const CustomerSupport = () => {
  return (
    <>

<div class="hero-basic">
  <div class="hero-basic__bg hero-basic__bg--blue">
    <div class="bg-shapes"></div>
  </div>
  <div class="hero-basic__content">
    <h1>Customer Support</h1>
    <p class="customizep">
    At ChirpChat, we're committed to providing exceptional customer support to ensure your experience with our chatbot service is seamless and hassle-free. Here's how you can get in touch with our support team
    </p>
    
  </div>
</div>


    
      <section className="whyChosefeatures" id="why">
        <div className="container">
          {/* <h2 className="featuresh2 tabheading">Customer Support</h2>
          <p className="customizep">At ChirpChat, we're committed to providing exceptional customer support to ensure your experience with our chatbot service is seamless and hassle-free. Here's how you can get in touch with our support team</p> */}
          <div className="row pt-5">
            <div className="col-lg-6">
              <div className="whyBox_2">
                <p className="customizeh1 "><b>Contact Information</b></p>
                <li className="mb-2 customizeli"><b>Email Support: </b> For general inquiries, technical assistance, or support-related questions, please email us at support@chirpchat.ai . Our support team will respond to your inquiry promptly during business hours.</li>
                <li className="mb-2 customizeli"><b>Phone Support: </b> If you prefer to speak with a support representative directly, you can reach us by phone at 732-354-1541. Our knowledgeable team members are available to assist you with any questions or concerns you may have.</li>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="whyBox_2">
                <p className="customizeh1"><b>Support Resources:</b></p>
                <li className="mb-2 customizeli"><b>Knowledge Base:</b> Explore our comprehensive knowledge base for answers to common questions, troubleshooting tips, and step-by-step guides on using ChirpChat. Access the knowledge base here.</li>
                <li className="mb-2 customizeli"><b>FAQs:</b>Check out our frequently asked questions (FAQs) page for quick answers to common inquiries about ChirpChat, its features, and functionalities. Visit the FAQs page here.</li>
              </div>
            </div>
            <div className="col-lg-6"><div className="whyBox_2">
              <p className="customizeh1"><b>Submit a Ticket:</b></p>
              <p className="customizep mb-2">If you have a specific issue or request that requires assistance from our support team, you can submit a support ticket using the form below. Please provide detailed information about your inquiry, and our team will follow up with you as soon as possible.</p>
            </div>
            </div>
            <div className="col-lg-6"><div className="whyBox_2">
              <p className="customizeh1"><b>Community Forum:</b></p>
              <p className="customizep mb-2">Join our community forum to connect with other users, share tips and best practices, and engage with our support team. Whether you're a seasoned user or just getting started with ChirpChat, our community forum is a valuable resource for learning and collaboration. Join the community here.</p></div>
            </div>
            <div className="col-lg-6">
              <div className="whyBox_2">
                <p className="customizeh1"><b>Feedback and Suggestions:</b></p>
                <p className="customizep mb-2">We value your feedback and suggestions for improving ChirpChat. If you have ideas for new features, enhancements, or ways to enhance your experience with our service, please let us know. Your input helps us continually improve and innovate. Send us your feedback here.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="whyBox_2">
                <p className="customizeh1"><b>Support Hours:</b></p>
                <ul><p className="customizep mb-2">Our support team is available to assist you during the following hours:</p></ul>
                <li className=" mb-2 customizeli"><b>Monday to Friday: </b> 9:00 AM - 6:00 PM (local time)</li>
                <li className=" mb-2 customizeli"><b>Saturday and Sunday: </b> Closed</li>
                <p className="customizep mb-2">For urgent inquiries outside of these hours, please email us at support@chirpchat.ai .</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="whyBox_2">
                <p className="customizeh1"><b>We're Here to Help:</b></p>
                <p className="customizep mb-2"> No matter what your question or concern may be, our dedicated support team is here to help. Whether you need technical assistance, have questions about using ChirpChat, or want to provide feedback, we're committed to providing the support you need to succeed.<br />Contact us today, and let us help you unlock the full potential of ChirpChat.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CustomerSupport