import React, { useEffect, useState } from "react";
import { allApi } from "../apiwrapper/api";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import Chatbot from "./Chatbot/Chatbot";
import Marketing from "../Home/Marketing";

function Marketingchatbot() {
  const [formData, setFormData] = useState([
    { question: "", answer: "" },
    { question: "", answer: "" },
    { question: "", answer: "" },
    { question: "", answer: "" },
    { question: "", answer: "" },
  ]);  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData
  //   });

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFormData = [...formData];
    updatedFormData[index][name] = value;
    setFormData(updatedFormData);
  };



  // };

  const handleSubmit = async (e) => {

    console.log("formData", formData);


  };



  return (
    <>
      <main>
        <section className="training-materials">
          <div className="col d-flex justify-content-center align-items-center">
            <h1 className="text-center text-dark " style={{ fontSize: "25px" }}> Marketing Chatbot  </h1>
          </div>

        </section>

        <section className="training-materials">
          <div className="row">
            <div className="col marketingCol border p-3">
              <Marketing />
            </div>
            <div className="col">
              {/* <form onSubmit={handleSubmit} className="dev">
                <div className="row">
                  <div className="col-sm-6">
                    <label>
                      Question 1:
                      <input type="text" className="text-color" name="welcome_message" value={formData.welcome_message} onChange={handleChange} />
                    </label>
                  </div>

                  <div className="col-sm-6">
                    <label>
                      Answer 1:
                      <input type="text" className="text-color" name="welcome_message" value={formData.welcome_message} onChange={handleChange} />
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <label>
                      Question 2:
                      <input type="text" className="text-color" name="welcome_message" value={formData.welcome_message} onChange={handleChange} />
                    </label>
                  </div>

                  <div className="col-sm-6">
                    <label>
                      Answer 2:
                      <input type="text" className="text-color" name="welcome_message" value={formData.welcome_message} onChange={handleChange} />
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <label>
                      Question 3:
                      <input type="text" className="text-color" name="welcome_message" value={formData.welcome_message} onChange={handleChange} />
                    </label>
                  </div>

                  <div className="col-sm-6">
                    <label>
                      Answer 3:
                      <input type="text" className="text-color" name="welcome_message" value={formData.welcome_message} onChange={handleChange} />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <label>
                      Question 4:
                      <input type="text" className="text-color" name="welcome_message" value={formData.welcome_message} onChange={handleChange} />
                    </label>
                  </div>

                  <div className="col-sm-6">
                    <label>
                      Answer 4:
                      <input type="text" className="text-color" name="welcome_message" value={formData.welcome_message} onChange={handleChange} />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <label>
                      Question 5:
                      <input type="text" className="text-color" name="welcome_message" value={formData.welcome_message} onChange={handleChange} />
                    </label>
                  </div>

                  <div className="col-sm-6">
                    <label>
                      Answer 5:
                      <input type="text" className="text-color" name="welcome_message" value={formData.welcome_message} onChange={handleChange} />
                    </label>
                  </div>
                </div>
                <div>
                  <Button variant="contained" size="large" type="submit">Save</Button>
                </div>
              </form> */}

              <form onSubmit={handleSubmit} className="dev">

                {formData.map((item, index) => (
                  <div className="row" key={index}>
                    <div className="col-sm-6">
                      <label>
                        {`Question ${index + 1}:`}
                        <input
                          type="text"
                          className="text-color"
                          name="question"
                          value={item.question}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </label>
                    </div>

                    <div className="col-sm-6">
                      <label>
                        {`Answer ${index + 1}:`}
                        <input
                          type="text"
                          className="text-color"
                          name="answer"
                          value={item.answer}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </label>
                    </div>
                  </div>
                ))}

                <div>
                  <Button variant="contained" size="large" type="submit">
                    Save
                  </Button>
                </div>
              </form>
            </div>
            <div className="col d-flex justify-content-end">
              <Chatbot chats={formData} />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Marketingchatbot;
