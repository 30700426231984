import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { BiLogInCircle } from "react-icons/bi";
import { SetpopupReducerData } from "../Store/reducer/PopupReducer";
import { useDispatch, useSelector } from "react-redux";
import { SetUserData } from "../Store/reducer/user";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data);
  const userID = useSelector((state) => state?.user?.data?._id);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuopen, setMenuopen] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuopen(true)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuopen(false);
  };

  const handleShowSignUp = () => {
    dispatch(
      SetpopupReducerData({
        modalType: "Signup",
        showModal: true,
      })
    );
  };

  const handleShowlogin = () => {
    setMenuopen(false);
    dispatch(
      SetpopupReducerData({
        modalType: "Login",
        showModal: true,
      })
    );
  };

  const handleShowlogout = () => {
    dispatch(SetUserData({}));
    navigate("/");
  };
  const handleShowDashboard = () => {
    setMenuopen(false);
    navigate("/dashboard");
  };
  return (
    <>
      {/* <div class="nav-topbar nav-topbar--dark">
		<ul>
			<li className="text-white"> <Link to="/support">Support</Link></li>
      <li class="hidden-xs text-white"><Link to="/faq">FAQ</Link></li>
      <li class="hidden-xs text-white"><Link to="/plan">Pricing</Link></li>

			<li class="hidden-xs text-white"><Link to="/marketingchatbot">Marketing Chatbot</Link></li>
			<li class="hidden-xs nav-topbar-lang">

</li>
{userID && userID ? (
                    ""
                  ) : (
			<li className="text-white" onClick={handleShowlogin}>Log in</li>
                  )
                }
		</ul>
	</div> */}
      <header className="headerHome headerTow customeheader">
    
        <div className="headerTwo">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto logo">
              <Link to= "/"><img style={{ height: "120px" }} src="/images/logo_new.png"/></Link> 
              </div>

              <div className="col d-flex justify-content-end align-items-center">
                <div className="navbar">
                  <Link to="/" className="nav-link text-white">
                    Home
                  </Link>
                  <Link to="/about" className="nav-link text-white">
                    About
                  </Link>
                  <Link to="/features" className="nav-link text-white">
                    Features
                  </Link>
                  <Link to="/blog" className="nav-link text-white">
                    Blog
                  </Link>
                  <Link to="/contact" className="nav-link text-white">
                    Contact Us
                  </Link>


                  {userID && userID ? (

                    <>
                      <div className="d-flex justify-content-end" >
                        <div
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          className="profile-menu-flex"
                        >
                          <ul className="loginDetail d-flex align-items-center mb-0 ps-3">
                            <li>
                              <div className="useLogin d-flex align-items-center">
                                <figure>
                                  <img src="/images/cat-10.jpg" alt="" />
                                </figure>
                                <p className="text-white">
                                  {user?.first_name}
                                  {/* {user?.last_name} */}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={menuopen}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        sx={{
                          ".MuiPaper-root": {
                            minWidth: "200px",
                            borderRadius: "1rem",
                            boxShadow:
                              "0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%)",
                          },
                        }}
                      >
                        <MenuItem
                          className="menu-items"
                          onClick={() => {
                            handleClose();
                            handleShowDashboard();
                          }}
                        >
                          Dashboard
                        </MenuItem>

                        <div
                          style={{
                            height: "1px",
                            backgroundColor: "var(--grey)",
                            width: "100%",
                          }}
                        />
                        <Link to="/apidettails">
                          <MenuItem onClick={handleClose} className="menu-items">
                            User Preferences
                          </MenuItem>
                        </Link>
                        <Link to="/account">
                          <MenuItem onClick={handleClose} className="menu-items">
                            Payment
                          </MenuItem>
                        </Link>
                        
                        <Link to="/agentdash">
                          <MenuItem onClick={handleClose} className="menu-items">
                            ChirpChatAgent
                          </MenuItem>
                        </Link>


                        <Link to="/">
                          <MenuItem
                            className="menu-items "
                            onClick={handleShowlogout}
                          >
                            Logout
                          </MenuItem>
                        </Link>
                      </Menu>
                    </>
                  ) : (
                    <>
                      {/* <Link
                      to="#"
                      className="nav-link"
                      onClick={handleShowSignUp}
                    >
                      Sign up
                    </Link> */}


                    </>

                  )}



                  {userID && userID ? (
                    ""
                  ) : (
                    <Link to="#" className="nav-link text-white" onClick={handleShowlogin}>
                      Login
                    </Link>
                  )}
                  <Link to ="/contact"><button className="demobtn">Book a Demo</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
