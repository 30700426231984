import React, { useEffect, useState } from "react";
import { allApi } from "../apiwrapper/api";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { Button, Fab, Slider } from "@mui/material";
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import AddIcon from '@mui/icons-material/Add';

function CustomizeChatBot() {
  const [formData, setFormData] = useState({
    chatbot_id: '',
    user_id: '',
    colour: '',
    border_colour: '',
    frequency_penalty: '',
    max_tokens: '',
    platform_id: '',
    presence_penalty: '',
    temperature: '',
    top_p: '',
    welcome_message: '',
    chatbot_title: '',
    sourceinfotech_watermark: '',
    placeholder: '',
    font: '',
    icons: null,
    hide_avatar_video:'',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const userID = useSelector((state) => state?.user?.data?._id);
  const { id } = useParams();

  const imagesurl = `${process.env.REACT_APP_API_BACKEND_URL}/static/`;

  const getChatBotListing = async () => {
    setIsLoading(true);

    try {
      const response = await allApi({
        url: `${process.env.REACT_APP_API_BACKEND_URL}/get_chatbot/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        formData: false,
      });

      const data = response?.data;

      setFormData({
        chatbot_id: data?.chatbot_id || '',
        colour: data?.colour || '',
        frequency_penalty: data?.frequency_penalty || '',
        max_tokens: data?.max_tokens || '',
        platform_id: data?.platform_id || '',
        presence_penalty: data?.presence_penalty || '',
        temperature: data?.temperature || '',
        top_p: data?.top_p || '',
        welcome_message: data?.welcome_message || '',
        font: data?.font || '',
        placeholder: data?.placeholder || '',
        sourceinfotech_watermark: data?.sourceinfotech_watermark || '',
        chatbot_title: data?.chatbot_title || '',
        border_colour: data?.border_colour || '',
        icon: data?.icon || null,
        iconPreview: data?.icon ? `${imagesurl}/${data.icon}` : null,
        hide_avatar_video:data?.hide_avatar_video || '',
      });

      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getChatBotListing();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    // setFormData({
    //   ...formData,
    //   [name]: files ? files[0] : value,
    // });

    if (name === "icon" && files.length > 0) {
      // If file input is changed, create a preview URL for the selected image
      const file = files[0];
      const previewURL = URL.createObjectURL(file);
      setFormData({
        ...formData,
        icon: file,
        iconPreview: previewURL,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formData", formData);
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "icon") {
        formDataToSend.append("file", value);
      } else {
        formDataToSend.append(key, value);
      }

    });

    try {
      setIsLoading(true);
      const response = await allApi({
        url: `${process.env.REACT_APP_API_BACKEND_URL}/update_chatbot_new/${userID}/${id}`,
        method: "PUT",
        body: formDataToSend,
        formData: true,
      });

      toast.success(response?.message);
      getChatBotListing();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fontOptions = [
    { label: "Ubuntu", value: "Ubuntu" },
    { label: "Google", value: "Roboto" },
    { label: "poppins", value: "poppins" },
  ];

  return (
    <>
      <main>
        <section className="training-materials">
          <div className="col d-flex justify-content-center align-items-center">
            <h1 className="text-center text-dark " style={{ fontSize: "25px" }}> Customize Chatbot  </h1>
          </div>
          <div className="col d-flex justify-content-end align-items-center">
            <Link to="/create" className="nav-link justify-content-end">
              <Fab color="primary" aria-label="add">
                <AddIcon />
              </Fab> Create Chatbot</Link>
          </div>
        </section>

        <section className="training-materials">
          <form onSubmit={handleSubmit} className="dev">
            <div className="row">
              <div className="col-sm-4">
                <label>ChatBot ID:</label>
                <input type="text" name="chatbot_id" value={formData.chatbot_id} readOnly />
              </div>

              <div className="col-sm-4">
                <label>ChatBot Title:</label>
                <input type="text" name="chatbot_title" value={formData.chatbot_title} onChange={handleChange} />
              </div>
              <div className="col-sm-4">
                <label>Sourceinfotech_watermark:</label>
                <select className="customselect" name="sourceinfotech_watermark" value={formData.sourceinfotech_watermark} onChange={handleChange}>
                  <option key="true" value="true">True</option>
                  <option key="false" value="false">false</option>
                </select>
              </div>
              <div className="col-sm-4">
                <label>Placeholder:</label>
                <input type="text" name="placeholder" value={formData.placeholder} onChange={handleChange} />
              </div>

              <div className="col-sm-4 align-self-center">
                <div className="row">
                  <div className="col">
                    <label>Icons:</label>
                    <input type="file" name="icon" onChange={handleChange} />
                  </div>
                  {formData.iconPreview &&
                    <div className="col d-flex justify-content-center align-items-center">
                      <img className="customImagenew" src={formData.iconPreview} alt="Icon" />
                    </div>}
                </div>
              </div>


              <div className="col-sm-4">
                <label>Font:</label>
                <select className="customselect" name="font" value={formData.font} onChange={handleChange}>
                  <option value="">Select a font</option>
                  {fontOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

              </div>

              <div className="col-sm-4 align-self-center">
                <label> ChatBot Color:</label>
                <input type="color" name="colour" value={formData.colour} onChange={handleChange} />
              </div>

              <div className="col-sm-4">
                <label> Border Color:</label>
                <input type="color" name="border_colour" value={formData.border_colour} onChange={handleChange} />
              </div>

              <div className="col-sm-4">
                <label> Frequency Penalty:</label>
                <input type="number" name="frequency_penalty" value={formData.frequency_penalty} onChange={handleChange} min="0" max="1" step="0.1" />
              </div>

              <div className="col-sm-4">
                <label>  Max Tokens:</label>
                <Slider
                  disabled={false}
                  marks={true}
                  value={parseInt(formData.max_tokens)}
                  onChange={(e) => setFormData({ ...formData, max_tokens: parseInt(e.target.value) })}
                  max={1000}
                  min={0}
                  valueLabelDisplay="true"
                />
              </div>

              <div className="col-sm-4">
                <label> Platform ID:</label>
                <input type="text" name="platform_id" value={formData.platform_id} onChange={handleChange} />

              </div>

              <div className="col-sm-4">
                <label>Presence Penalty:</label>
                <input type="number" name="presence_penalty" value={formData.presence_penalty} onChange={handleChange} min="0" max="1" step="0.1" />

              </div>

              <div className="col-sm-4">
                <label> Temperature:</label>
                <Slider
                  disabled={false}
                  onChange={(e) => setFormData({ ...formData, temperature: e.target.value })}
                  marks={true}
                  max={1}
                  min={0}
                  step={0.1}
                  value={parseFloat(formData.temperature)}
                  valueLabelDisplay="true"
                />
              </div>

              <div className="col-sm-4">
                <label> Top P:</label>
                <input type="number" name="top_p" value={formData.top_p} onChange={handleChange} min="0" max="1" step="0.1" />
              </div>

              <div className="col-sm-4">
                <label>
                  Welcome Message:
                  <input type="text" className="text-color" name="welcome_message" value={formData.welcome_message} onChange={handleChange} />
                </label>
              </div>
              <div className="col-sm-4">
                <label>avatar Video:</label>
                <select className="customselect" name="hide_avatar_video" value={formData.hide_avatar_video} onChange={handleChange}>
                  <option key="true" value="true">True</option>
                  <option key="false" value="false">false</option>
                </select>
              </div>
            </div>
            <div>
              <Button variant="contained" size="large" type="submit">Save</Button>
            </div>
          </form>
        </section>
      </main>
    </>
  );
}

export default CustomizeChatBot;
