import React, { useState } from 'react';
import { SetpopupReducerData } from '../Store/reducer/PopupReducer';
import { useDispatch } from 'react-redux';
import { allApi } from '../apiwrapper/api';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import apiURL from '../Store/Action/api-url';



const ForgotModel = () => {
  const [showModal, setShowModal] = useState(true);
 
  const [email, setEmail] = useState('');


  const dispatch = useDispatch();
  
  const navigate=useNavigate();
  const handleClosepopup = () => {
    dispatch(SetpopupReducerData({ modalType: "", showModal: false }));
  };
 
  const handleSendOtp= async(e) => {
    e.preventDefault();
    try {
      await allApi({

        url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.forgotpassword}`,
        method: "POST",
          body: {
            Email_ID:email,
          },
          headers: {
              'Content-Type': 'application/json'
          },

          formData: false,
      }).then((data) => {

        if(data?.status)
        {
            handleClosepopup();
            dispatch(SetpopupReducerData({
                modalType:"Otp",
                showModal:true,
                Email:email,
            }))
            
            toast.success(data?.message);
        }
          toast.error(data?.error);
      });
  } catch (error) {
  } finally {
  }


  };


 
  return (
    <>
      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header justify-content-center">
                <h5 className="modal-title ">Forgot Password?</h5>
                <br/>
                <button type="button " className="btn-close position-absolute end-0 me-3" onClick={handleClosepopup} aria-label="Close">
                </button>

              </div>
              <div>
              <p className='text-center mb-4'>
                Please Enter  Your Register  Email 
              </p>
              </div>
              <form onSubmit={handleSendOtp}>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary text-primary">Send Otp </button>
                </div>
                </form>
            </div>
          </div>
         
        </div>
      )}
    </>
  );
};

export default ForgotModel;
