import React from "react";
const Features = () => {
  return (
    <>

<div class="hero-basic">
  <div class="hero-basic__bg hero-basic__bg--blue">
    <div class="bg-shapes"></div>
  </div>
  <div class="hero-basic__content">
    <h1>Features</h1>
    <p class="customizep">Discover the powerful capabilities of Chirp Chat and how it can transform your business operations and customer engagement.</p>
    
  </div>
</div>
    

    
      <section className="whyChosefeatures" id="why"><div className="container">
        {/* <h2 className="featuresh2 tabheading ">Features</h2>
        <div className="heading-container">
          <h1>Discover the powerful capabilities of Chirp Chat and how it can transform your business operations and customer engagement</h1>
        </div> */}

        <div className="row pt-5">
          <div className="col-lg-6">
            <div className="whyBox_2">
              <h1 className="text-center customizeh1 "><b>Intelligent Responses</b></h1>
              <p className="customizep">Our chatbot uses advanced generative AI technology to understand and respond to customer queries with unparalleled accuracy and relevance. From simple inquiries to complex requests, our chatbot delivers intelligent responses that exceed expectations.</p>
            </div>
          </div>
          <div className="col-lg-6"><div className="whyBox_2">
            <h1 className="text-center customizeh1 "><b>Contextual Integration</b></h1>
            <p className="customizep">Unlike traditional chatbots, Chirp Chat seamlessly integrates with your existing business systems and data sources, including website information, documents, calendars, and more. This contextual integration ensures that the chatbot provides relevant and personalized responses based on real-time data.</p>
          </div>
          </div>
          <div className="col-lg-6"><div className="whyBox_2">
            <h1 className="text-center customizeh1"><b>Personal Assistant Capabilities</b></h1>
            <p className="customizep">Think of Chirp Chat as your personal assistant. In addition to answering questions, our chatbot can schedule meetings, manage appointments, and keep your business calendar in sync—all within the chat interface. Say goodbye to manual tasks and hello to increased productivity.</p></div>
          </div>
          <div className="col-lg-6">
            <div className="whyBox_2">
              <h1 className="text-center customizeh1"><b>Proactive Engagement</b></h1>
              <p className="customizep"> Our chatbot doesn't just wait for customer inquiries; it proactively engages with users based on their behavior and preferences. Whether it's offering product recommendations, sharing updates, or providing timely reminders, our chatbot ensures continuous engagement and interaction.</p>
            </div>
          </div>
          <div className="col-lg-6"><div className="whyBox_2">
            <h1 className="text-center customizeh1"><b>Customization Options</b></h1>
            <p className="customizep">Every business is unique, and so is Chirp Chat. Customize the chatbot's responses, branding, and functionality to align with your brand voice and objectives. Our flexible customization options ensure that the chatbot resonates with your target audience and reflects your brand identity.</p>
          </div>
            <div className="whyBox_2">
              <h1 className="text-center customizeh1"><b>Analytics and Insights</b></h1>
              <p className="customizep"> Gain valuable insights into your website visitors, customer behavior, and web traffic with our analytics tools. Use this data to refine your marketing strategies, personalize communications, and increase revenue.</p>
            </div>
          </div>
          <div className="col-lg-6"><div className="whyBox_2">
            <h1 className="text-center customizeh1"><b>Scalability and Reliability</b></h1>
            <p className="customizep">Whether you're a small startup or a large enterprise, Chirp Chat scales with your business needs. Our reliable infrastructure ensures seamless performance and uptime, allowing you to focus on growing your business without worrying about technical limitations.</p>
          </div>
          </div>
        </div>
      </div>
      </section>
      <section className="my-4 text-center" >
        <div className="heading-container">
          <h1>Explore these features and more with Chirp Chat. Contact us today to<br/> schedule a demo and experience the power of AI-driven customer engagement.</h1>
        </div>
      </section>
    </>
  )
}

export default Features