import React from 'react'
import { FaFacebookF, FaLinkedin, FaTwitter, FaInstagram } from 'react-icons/fa'
import { CiLocationOn } from "react-icons/ci";
import { AiOutlineMail } from "react-icons/ai";
import { PiPhone } from "react-icons/pi";



import { Link } from 'react-router-dom'

function Footer() {
  return (

    <footer className='mt-4 ' >
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-12">

            <hr className="my-5" />
          </div>
          <div className="col-xl-4 text-center text-md-start">
            {/* <h3 className="text-Black">Company</h3> */}
            <ul className="fotLink">

              <Link to="/about">
                <li>
                  <a href="">About</a>
                </li>
              </Link>

              <Link to="/contact">
                <li >
                  <a href="" className='text-black'>Contact us</a>
                </li>
              </Link>

              <Link to="/plan">
                <li>
                  <a href="">Pricing</a>
                </li>
              </Link>

              <Link to="/support">
                <li>
                  <a href="">Customer Support</a>
                </li>
              </Link>


            </ul>
          </div>
          <div className="col-xl-4 text-center text-md-start">

            {/* <h3></h3> */}

            <ul className="fotLink">

              <Link to="/termandconditon">
                <li>
                  <a href="">Terms & Conditions</a>
                </li>
              </Link>
              <Link to="/policey">
                <li>
                  <a href="">Privacy Policy</a>
                </li>
              </Link>
              <Link to="/howworks">
                <li>
                  <a href="">How It Works</a>
                </li>
              </Link>
              <Link to="/faq">
                <li>
                  <a href="">FAQ</a>
                </li>
              </Link>
              <Link to="/marketingchatbot">
                <li>
                  <a href="">Marketing Chatbot</a>
                </li>
              </Link>
            </ul>
          </div>

          <div className="col-md-4 text-center text-md-start">
            <ul className="fotLink">
         
               <li className="d-flex align-items-start address" >
              <CiLocationOn className='mt-0 me-4' />
              <div>
                3840 Park Avenue, STE C-205, <br />Edison, NJ 08820
              </div>
          </li>
              
              <li className="d-flex align-items-start ">
              <AiOutlineMail className='mt-0 me-4' />
            <div >
            <a href="mailto:info@chirpchat.ai">
                  info@chirpchat.ai     
              </a>  
                                       </div>
          </li>
              <li className="d-flex align-items-start ">
              <PiPhone className='mt-0 me-4' />
              <div>
                <p>732-354-1541</p>
                </div>
              </li>
            
              <ul className="socalMedia fotLink">
                <li>
                  <a href="https://www.facebook.com/profile.php?id=61558703439738&mibextid=LQQJ4d" target="_blank">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/Source_Infotech" target="_blank">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/102490618/admin/feed/posts/" target="_blank">
                  <FaLinkedin />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/chirpchat_ai/?next=%2Fexplore%2Ftags%2Fchirpchat%2F&hl=en" target="_blank">
                  <FaInstagram />
                  </a>
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </footer>

  )
}

export default Footer