import React, { useState } from "react";
import { allApi } from "../apiwrapper/api";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Chatbotlisting from "./Chatbotlisting";
import FileAndTextInput from "./Chatbot/FileAndTextInput"
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import copy from "copy-to-clipboard";
import apiURL from "../Store/Action/api-url";
import { Button } from "react-bootstrap";
import ClearIcon from '@mui/icons-material/Clear';

function Createchatbot() {
  const [websiteUrl, setWebsiteUrl] = useState([]);
  const [resUrls, setResUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCreate, setloadingCreate] = useState(false);
  const [chatbotId, setChatbotId] = useState();
  const [Url_ID, setUrl_ID] = useState();
  const [files, setFiles] = useState([]);

  const userID = useSelector((state) => state?.user?.data?._id);

  const handlefetchlinks = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await allApi({
        url: `${process.env.REACT_APP_API_BACKEND_OTHER_URL}${apiURL.fetch_suburls}`,

        method: "POST",
        body: {
          urls: [websiteUrl],
          userID: userID,
        },
        headers: {
          "Content-Type": "application/json",
        },

        formData: false,
      }).then((data) => {
        setResUrls([...new Set(data?.payload?.result[0]?.sublinks)]);

        setIsLoading(false);
      });
    } catch (error) {
      setResUrls([]);
    } finally {
      setIsLoading(false);
    }
  };
  const removeUrlAtIndex = (indexToRemove) => {
    const updatedUrls = resUrls.filter((_, index) => index !== indexToRemove);
    setResUrls(updatedUrls);
  };



  // const handleCreateChatBot = async (e) => {
  //   e.preventDefault();
  //   // const url="http://192.168.20.89:5000/create_chatbot"

  //   setloadingCreate(true);

  //   try {
  //     await allApi({
  //       url: `${process.env.REACT_APP_API_BACKEND_OTHER_URL}${apiURL.create_chatbot}`,

  //       method: "POST",
  //       body: {
  //         urls: [websiteUrl],
  //         userID: userID,
  //       },
  //       headers: {
  //         "Content-Type": "application/json",
  //       },

  //       formData: false,
  //     }).then((data) => {
  //       toast.success(data?.payload?.result);
  //       setChatbotId(data?.payload?.chatbotID)
  //       setUrl_ID(data?.payload?.Url_ID)
  //       setloadingCreate(false);
  //     });
  //   } catch (error) {
  //   } finally {
  //     setloadingCreate(false);
  //   }
  // }

  const handleCreateChatBot = async (e) => {
    e.preventDefault();
    console.log("websiteUrl", websiteUrl);
    const url = `${process.env.REACT_APP_API_BACKEND_OTHER_URL}${apiURL.create_chatbot}`

    setloadingCreate(true);
    const formDataPost = new FormData();
    formDataPost.append('userID', userID);
    // formDataPost.append('urls[]', websiteUrl);
    resUrls.length > 0 && resUrls.map((url) => {
      formDataPost.append('urls[]', url)
    });
    files.length > 0 && files.map((fil) => {
      formDataPost.append('files[]', fil)
    });

    try {
      await allApi({
        // url: `${process.env.REACT_APP_API_BACKEND_OTHER_URL}${apiURL.create_chatbot}`,
        url: url,

        method: "POST",
        // body: {
        //   urls: [websiteUrl],
        //   userID: userID,
        // },
        body: formDataPost,
        formData: true,
      }).then((data) => {
        toast.success(data?.payload?.result);
        setChatbotId(data?.payload?.chatbotID)
        setUrl_ID(data?.payload?.Url_ID)
        setloadingCreate(false);
      });
    } catch (error) {
    } finally {
      setloadingCreate(false);
    }
  }

  function onCancel(e) {
    e.preventDefault();
    setWebsiteUrl([]);
    setResUrls([]);
    setIsLoading(false);
    setloadingCreate(false);
    setFiles([]);
  }

  const copyToClipboard = () => {
    const textToCopy = `<chat-bot platform_id="2d798e16-0170-4f32-90b8-569812585aab" user_id="${userID}" chatbot_id="${chatbotId}"></chat-bot><script src="${process.env.REACT_APP_CHATBOT_LOADER_SCRIPT}" defer></script>`;
    copy(textToCopy);
    toast.success("Copied to clipboard!");
  };

  const handleTextChange = (text) => {
    setWebsiteUrl(text);
  };

  const handleFileChange = (files) => {
    setFiles(files);
  };

  return (
    <>

      <main>

        {/* <header>
          <h1 className="text-bold " style={{ fontSize: "25px" }}>Create Chatbot</h1>
          <div className="col d-flex justify-content-end align-items-center">
            <div className="navbar">
              <Link to="/dashboard" className="nav-link"
              >Dashboard</Link>

            </div>
          </div>
        </header> */}
        <section className="training-materials">
          <div className="col d-flex justify-content-center align-items-center">
            <h1 className="text-center text-dark " style={{ fontSize: "25px" }}> Create Chatbot  </h1>
          </div>
          <div className="col d-flex justify-content-center align-items-center">
            <p>Follow just simple steps and create your AI powered chatbot. And apply it on your website in just some clicks.</p>
          </div>
        </section>
        <section className="training-materials">
          <div className="dev">
            <form id="">
              <div className="row d-flex justify-content-center">
                <div className="col">
                  <label for="website-url">Your Website</label>
                  {/* <input
                    type="text"
                    id="website-url"
                    name="website_url"
                    value={websiteUrl}
                    placeholder="Enter your website URL to be trained on"
                    onChange={(e) => {
                      setWebsiteUrl(e.target.value);
                    }}

                  /> */}

                  {/* <FileAndTextInput inputTexts={setWebsiteUrl} filess={setFiles} /> */}
                  <FileAndTextInput
                    onTextChange={handleTextChange}
                    onFileChange={handleFileChange}
                  />
                </div>


                <br />
                {isLoading ? (
                  <Oval
                    visible={true}
                    height="50"
                    width="50"
                    color="#4fa94d"
                    ariaLabel="oval-loading"
                    wrapperStyle={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                ) : (
                  ""
                )}
                {resUrls?.length == 0 ? (
                  <div className="d-flex justify-content-center">
                    <Button
                      type="button"
                      id="fetch-links"
                      onClick={(e) => {
                        handlefetchlinks(e);
                      }}
                    >
                      Fetch Links
                    </Button>
                    <br />
                  </div>
                ) : (
                  <>
                    <div className="row d-flex justify-content-center">



                      {resUrls?.map((links, index) => {
                        return (
                          <div key={index} className="col-sm-4">
                            {/* <div className="url-list"> */}
                            <div key={index} className="input-group">
                              <input
                                type="text"
                                className="input-field"
                                value={links}
                              />

                              <span className="icon-clear"
                                onClick={() => removeUrlAtIndex(index)}
                              ><ClearIcon /></span>
                            </div>
                            {/* </div> */}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                <br />

                {loadingCreate ? (
                  <Oval
                    visible={true}
                    height="50"
                    width="50"
                    color="#4fa94d"
                    ariaLabel="oval-loading"
                    wrapperStyle={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                ) : (
                  ""
                )}

                {/* <button type="submit" id="create-chatbot" onClick={(e) => handleCreateChatBot(e)}>
                  Create
                </button> */}
                <div className="row">
                  <div className="col-sm-6 d-flex justify-content-center">
                    <Button type="submit" id="create-chatbot" onClick={(e) => handleCreateChatBot(e)}>Create</Button>
                  </div>
                  <div className="col-sm-6 d-flex justify-content-center">
                    <Button className="cancelButton" onClick={onCancel}> Cancel</Button>
                  </div>
                </div>



              </div>
            </form>
          </div>

        </section>
        {chatbotId && chatbotId ?
          <section className="upload-area">
            <div>
              <p>
                {`To embed your chatbot onto your website, paste this snippet into your website's HTML file, right before the closing body tag  </body> .
For other website management systems, book a meeting with us.`}
              </p>
            </div>
            <>

              <div className="flex items-center" style={{ display: 'flex' }}>
                <pre className="bg-gray-100 rounded py-4 px-2 text-sm text-gray-800 overflow-x-auto">
                  &lt;chat-bot platform_id="2d798e16-0170-4f32-90b8-569812585aab" user_id="{userID}" chatbot_id="{chatbotId}"&gt;&lt;/chat-bot&gt;&lt;script src="{process.env.REACT_APP_CHATBOT_LOADER_SCRIPT}" defer&gt;&lt;/script&gt;</pre
                ><div>
                  <button className="flex gap-2 border-2 px-5 py-2 ml-4 rounded-lg text-themeGreen mr-4 hover:bg-green-50 border-themeGreen font-bold" onClick={copyToClipboard}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="ml-[-10px]"><path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path><path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path></svg>Copy</button></div></div>



            </>

          </section> : ""
        }
      </main>
    </>



  );
}

export default Createchatbot;
