import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';

const Congratulations=()=>{
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = "success";
    const sessionId = queryParams.get('session_id');

    if (status && sessionId) {
        fetch(`https://us2.sourcesoftsolutions.com:8065/handle-payment?status=${status}&session_id=${sessionId}`)
            .then(response => {
                if (response.ok) {
                    // Handle successful response
                    console.log('Payment status handled successfully');
                } else {
                    // Handle error response
                    console.error('Error handling payment status');
                }
            })
            .catch(error => {
                // Handle fetch error
                console.error('Error fetching payment status:', error);
            });
    } else {
        console.error('Missing status or session_id parameters');
    }
}, [location.search]);
    return(
        <>
        <section>
        <div className="container mt-4 text-center"> 
        <div className="card">
          <div style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: '0 auto' }}>
            <i className="checkmark">✓</i>
          </div>
          <h1>Success</h1> 
          <p>Payment Complete <br/> Successfully!</p>
        </div>
      </div>
        </section>
        </>
    )
}

export default Congratulations;