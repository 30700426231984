import React from "react";
import { Link } from "react-router-dom";


const Home = () => {
    return (
        <div className="home-container">

            {/* <section className="full-width-image-container">
                <img src="images/home.png" alt="Decorative" className="full-width-image" />
            </section> */}
            <div class="hero-basic">
  <div class="hero-basic__bg hero-basic__bg--blue"> 
    <div class="bg-shapes"></div>
  </div>
  <div class="hero-basic__content">
    <h1>Welcome to Chirp Chat</h1>
    <p class="lead">where we redefine the essence of customer interaction with our revolutionary AI-powered chatbot service. Dive into a world where your chatbot does more than just answer questions—it becomes an indispensable personal assistant tailored to your business's unique needs.</p>
    <div class="hero-basic__btns">
	    						    {/* <a href="/about-us/contact-us/" class="btn btn--primary">
			    				    </a> */}
                    <Link to="/contact"  className="btn btn--primary text-white">Contact us</Link>
					    </div>
  </div>
</div>
            {/* <section className="top-section" style={{color:"#fff"}}>
                <div className="heading-container">
                    <h1>Welcome to Chirp Chat, where we redefine the essence of customer interaction with our revolutionary AI-powered chatbot service. Dive into a world where your chatbot does more than just answer questions—it becomes an indispensable personal assistant tailored to your business's unique needs.</h1>
                   
                </div>
                <div className="graphic-container">
                </div>
            </section> */}





            <section className="top-section">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-5">
                            <img src="images/image_1.jpg" alt="LLM Graphic" className="rounded-4 img-fluid" />
                        </div>
                        <div className="col-md-6">
                            <div className="heading-container">
                                <h1 className="customeh1new">Transform Your Customer Engagement with Advanced AI</h1>
                                <p className="lead">Imagine a chatbot that's not just smart but intuitively understands your business's heartbeat. Our AI-driven assistant delves deep into your business information, extracting insights from your website, Google Drive, calendars, and more. It's not just a chatbot; it's your gateway to seamless customer interaction and operational efficiency.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="top-section ">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        
                        <div className="col-md-6 ">
                            <div className="heading-container">

                            <h1 className="customeh1new">Empower Your Business with Versatile Capabilities</h1>
                            <ul>
                                <p className="lead">
                                    <b>Dynamic Interaction:</b> Answering customer questions with precision, tapping into a vast reservoir of your business's digital footprint.
                                </p>
                                <p className="lead">
                                    <b>Proactive Scheduling:</b> Seamlessly schedule meetings, manage appointments, and keep your business calendar in sync, all within the chat interface.
                                </p>
                                <p className="lead">
                                    <b>Multifaceted Integration:</b> From the latest product updates to essential document retrieval, our chatbot is in constant sync with your business pulse.
                                </p>
                            </ul>
                            </div>
                        </div>
                        <div className="col-md-5 ">
                            <img  src="images/images_2.jpeg" alt="LLM Graphic" className="rounded-4  img-fluid " />
                        </div>
                    </div>
                </div>
            </section>


            <section className="top-section">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-5 ">
                            <img  src="images/image_3.jpg" alt="LLM Graphic" className="rounded-4  img-fluid " />
                        </div>
                        <div className="col-md-6 ">
                            <div className="heading-container">

                            <h1 className="customeh1new">Customized for Your Business</h1>
                            <p className="lead">Every business is unique, and so is our chatbot. Tailored to resonate with your brand's voice and objectives, it serves as a dynamic extension of your team, providing personalized interactions that resonate with your customers.</p>
                        </div>
                        
                        </div>
                    </div>
                </div>
            </section>


            <section className="top-section">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-6 ">
                            <div className="heading-container">
                            <h1 className="customeh1new">Elevate Your Outreach, Amplify Your Impact</h1>
                            <p className="lead">Discover the power of informed decision-making. Our analytics provide a comprehensive view of your web traffic, offering clarity on customer journeys, preferences, and potential engagement points. Utilize this intelligence to fine-tune your marketing campaigns, send personalized emails, and enhance your outreach strategies. With our chatbot, every interaction is an opportunity to grow your business and revenue.</p>
                        </div>
                        </div>
                        <div className="col-md-5">
                            <img src="images/image_4.jpg" alt="LLM Graphic" className="rounded-4   img-fluid " />
                        </div>
                    </div>
                </div>
            </section>


            <section className="top-section">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-5 ">
                            <img src="images/image_1.jpg" alt="LLM Graphic" className="rounded-4 img-fluid " />
                        </div>
                        <div className="col-md-6 ">
                            <div className="heading-container">
                            <h1 className="customeh1new">Join the Revolution</h1>
                            <p className="lead">Embrace the future with ChirpChat. Where others see a chatbot, we see a transformational tool that propels your business forward. Engage with us, and together, let's unlock the full potential of your online presence. Because with ChirpChat, the future of customer interaction is here and now.</p>
                        </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="text-center heading-container my-5">
                <h1 className="text-center">Start your journey today. Contact us to explore how our AI-powered <br/>chatbot can revolutionize your  <br/>customer engagement and business growth.</h1>
            </div>
            <div className="text-center my-5">
            <Link to ="/contact"><button className="demobtn">Book a Demo</button></Link>

            </div>


        </div>
        
    );
}

export default Home;
