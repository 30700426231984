import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { allApi } from "../apiwrapper/api";
import { toast } from "react-toastify";
import apiURL from "../Store/Action/api-url";

const UserApiDettails = () => {
  const [inputData, setInputData] = useState({
    browserless: "",
    hugging_face: "",
    openai: "",
    serper: "",
  });
  const userID = useSelector((state) => state?.user?.data?._id);


  const handleChange = (e) => {
    setInputData({
        ...inputData,
        [e.target.name]: e.target.value,
      });
  };


  const getApiDettails =async () => {
    try {
        await allApi({
          url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.get_keys}/${userID}`,
          method: "GET",
         
          headers: {
            "Content-Type": "application/json",
          },
  
          formData: false,
        }).then((data) => {
            console.log(data,"hjggsjgsggs")
     
           setInputData({
               browserless:data?.data?.browserless|| '',
               hugging_face: data?.data?.hugging_face || '',
               openai: data?.data?.openai || '',
               serper: data?.data?.serper || '',
           });

       
            });
      } catch (error) {
      } finally {

            }
  };

  const handleSubmit =async () => {
    try {
        await allApi({
          url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.update_keys}/${userID}`,
          method: "PUT",
          body: {
            browserless: inputData?.browserless,
            hugging_face: inputData?.hugging_face,
            openai: inputData?.openai,
            serper: inputData?.serper,
          },
          headers: {
            "Content-Type": "application/json",
          },
  
          formData: false,
        }).then((data) => {
       toast.success(data?.message);
       getApiDettails();
       if(data?.message)
       {
           setInputData({
               browserless: "",
               hugging_face: "",
               openai: "",
               serper: "",
           });

       }
            });
      } catch (error) {
      } finally {

        setInputData({
            browserless: "",
            hugging_face: "",
            openai: "",
            serper: "",
        });      }
  };


  useEffect(()=>{
    getApiDettails();
  },[userID])

  return (
    <>

<h2 className="text-center text-black mb-4 customizeh1 mt-4">User Preferences </h2>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 mt-5">
            <div className="form-group">
              <label for="browserless" className="customisedh3">Browserless Key</label>
              <input
                type="text"
                id="browserless"
                className="form-control "
                placeholder=" Enter Browserless key"
                value={inputData?.browserless}
                onChange={handleChange}
                name="browserless"
              />
            </div>
          </div>
          <div className="col-md-6 mt-5">
            <div className="form-group">
              <label for="hugging_face" className="customisedh3">Hugging Face Key</label>
              <input
                type="text"
                id="hugging_face"
                className="form-control"
                placeholder="Enter Hugging Face Key"
                value={inputData?.hugging_face}
                onChange={handleChange}
                name="hugging_face"
              />
            </div>
          </div>
          <div className="col-md-6 mt-5">
            <div className="form-group">
              <label for="openai" className="customisedh3">Openai Key</label>
              <input
                type="text"
                id="openai"
                className="form-control"
                placeholder="Enter Openai Key"
                value={inputData?.openai}
                onChange={handleChange}
                name="openai"
              />
            </div>
          </div>
          <div className="col-md-6 mt-5">
            <div className="form-group">
              <label for="serper" className="customisedh3">Serper Key</label>
              <input
                type="text"
                id="serper"
                className="form-control"
                placeholder="Enter Serper key"
                value={inputData?.serper}
                onChange={handleChange}
                name="serper"
              />
            </div>
          </div>
          <div className="col-md-12 text-center mt-5">
            <button
              type="button"
              className="btn btn-primary "
              style={{color:"blue"}}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserApiDettails;
