import React from "react";
import { useSelector } from "react-redux";
import Signup from "./SignUP";
import LoginModel from "./LoginModel";
import ForgotModel from "./ForgotModel";
import OtpModel from "./OtpModel";
import ChangePasswordModel from "./ChangePasswordModel";

function OpenModal() {
  
  const { modalType, showModal } = useSelector((state) => state?.PopupReducer?.modal);

  return <>

    {showModal && <>
      <div className="backdrop">
        
      </div>
      {modalType === "Signup" && <Signup />}
      {modalType==="Login" && <LoginModel/>}
      {modalType=="Forgot" && <ForgotModel/>}
      {modalType=="Otp" && <OtpModel/>}
      {modalType=="ChangePassword" && <ChangePasswordModel/>}



    </>
    }
  </>;
}

export default OpenModal;
