
import React, { useState } from "react";
import { Link } from "react-router-dom";

function Sidebar() {

  return (
    <>
      <aside>
        <div className="sidebar">
          <div className="sidebar-logo">
            {/* <img src="logo.png" alt="Chatsimple Logo" /> */}
            <h2>Chat Dashboard</h2>
          </div>
          <nav className="sidebar-nav">
            <a ><Link to ="/dashboard" >Dashboard </Link></a>
           <a><button 
        //    onClick={addpayementgatway}
           >
              Payment gatway
            </button> </a> 
           <Link to="/dashboard"> <a><button>
              Chatbot
            </button> </a> </Link>

            <Link to="/create"> <a><button>
            Create ChatBot
            </button> </a> </Link>

          </nav>
        </div>
      </aside>
    
          
     </>
        
   
    
  );
}

export default Sidebar;
