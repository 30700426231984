import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Layout from './Components/Layout/Layout';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Createchatbot from './Components/Dashboard/Createchatbot';
import SignupModal from './Components/PopupModel/SignUP';
import { useSelector } from 'react-redux';
import OpenModal from './Components/PopupModel/OpenModal';
import Chatbotlisting from './Components/Dashboard/Chatbotlisting';
import Sidebar from './Components/Dashboard/Sidebar';
import CustomizeChatBot from './Components/Dashboard/CustomizeChatBot';
import Home from './Components/Home/Home';
import About from './Components/Home/About';
import CaseStudies from './Components/Home/CaseStudies';
import Features from './Components/Home/Features';
import BlogResourcesPage from './Components/Home/BlogsResources';
import TermAndCondition from './Components/Home/TermAndConditions';
import PrivacyPolicey from './Components/Home/PrivacyPolicy';
import PricePlan from './Components/Home/PricePlan';
import { FaQ } from 'react-icons/fa6';
import FAQ from './Components/Home/FAQ';
import ContactUs from './Components/Home/ContactUs';
import { FcCustomerSupport } from 'react-icons/fc';
import HowITWorks from './Components/Home/HowITWorks';
import CustomerSupport from './Components/Home/CustomerSupport';
import Marketing from './Components/Home/Marketing';
import Marketingchatbot from './Components/Dashboard/Marketingchatbot';
import Payment from './Components/Home/Payment';
import Congratulations from './Components/Home/Congratulations';
import UserApiDettails from './Components/Home/UserApiDettails';
import FileAndTextInput from './Components/Dashboard/Chatbot/FileAndTextInput';
import TrainingMaterial from './Components/Dashboard/TrainingMaterial';
import ChatbotHumanDash from './Components/Dashboard/ChatbotHumanDash';

import ReactPixel from "react-facebook-pixel";

import { InlineWidget, PopupButton, PopupWidget } from "react-calendly";
import ProtectedRoute from './Components/Home/ProtectedRoute';

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const showModal = useSelector((state) => state?.PopupReducer?.modal?.showModal);

  const { data } = useSelector((state) => state?.user);
  console.log("data", data);
  const userId = data?._id;

  console.log("userId", userId);
  useEffect(() => {
    setShowPopup(showModal);
    return () => {
      setShowPopup(false);
    };
  }, [showModal]);


 
  return (


    <div className="App">
      {showPopup && <OpenModal />}


      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* <Router> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/trainingmaterial/:id' element={<TrainingMaterial />} />
          {/* <Route path='/signup' element={<SignupModal />} /> */}
          <Route path='/sidebar' element={<Sidebar />} />
          <Route path='/about' element={<About />} />
          <Route path='/howworks' element={<HowITWorks />} />
          <Route path='/blog' element={<BlogResourcesPage />} />
          <Route path='/features' element={<Features />} />
          <Route path='/termandconditon' element={<TermAndCondition />} />
          <Route path='/policey' element={<PrivacyPolicey />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/support' element={<CustomerSupport />} />
          <Route path='/marketing' element={<Marketing />} />
          <Route path='/marketingchatbot' element={<Marketingchatbot />} />
          <Route path='/plan' element={<PricePlan />} />

          {/* {userId?(
            <>
          <Route path='/account' element={<Payment />} />
          <Route path='/success' element={<Congratulations />} />
          <Route path='/apidettails' element={<UserApiDettails />} />
          <Route path="/agentdash" element={<ChatbotHumanDash />} />
          <Route path="/agentdash/:session_id/:chatbot_id" element={<ChatbotHumanDash/>} />

          <Route path='/dashboard' element={<Chatbotlisting />} />



          <Route path='/casestudies' element={<CaseStudies />} />
          <Route path='/create' element={<Createchatbot />} />
          <Route path="/updatechatbot/:id" element={<CustomizeChatBot />} />
          <Route path='/abc' element={<FileAndTextInput />} />
          <Route path='/create' element={<Createchatbot />} />
          <Route path="/updatechatbot/:id" element={<CustomizeChatBot />} />
            </>
          ):(
            
            <Route path="*" element={<Navigate to="/signup" />} />
            



          )} */}

{userId ? (
              <>
                <Route path='/account' element={<Payment />} />
                <Route path='/success' element={<Congratulations />} />
                <Route path='/apidettails' element={<UserApiDettails />} />
                <Route path="/agentdash" element={<ChatbotHumanDash />} />
                <Route path="/agentdash/:session_id/:chatbot_id" element={<ChatbotHumanDash />} />
                <Route path='/dashboard' element={<Chatbotlisting />} />
                <Route path='/casestudies' element={<CaseStudies />} />
                <Route path='/create' element={<Createchatbot />} />
                <Route path="/updatechatbot/:id" element={<CustomizeChatBot />} />
                <Route path='/abc' element={<FileAndTextInput />} />
              </>
            ) : (
              <Route
                path="*"
                element={
                  <ProtectedRoute userId={userId}>
                    <SignupModal />
                  </ProtectedRoute>
                }
              />
            )}
          

        </Route>
        {/* <Route path='/dashboard' element={<Dashboard />} /> */}







      

        {/* </Route> */}
        {/* <Route path='/dashboard' element={<Dashboard />} /> */}




      </Routes>
      {/* </Router> */}
    </div>
  );
}

export default App;
