import React, { useEffect, useState } from "react";
import { allApi } from "../apiwrapper/api";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Createchatbot from "./Createchatbot";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import copy from "copy-to-clipboard";
import { API } from "../apiwrapper";
import apiURL from "../Store/Action/api-url";




function Chatbotlisting() {
  const [IsLoading, setIsLoading] = useState(false);
  const [chatbotListing, setchatbotListing] = useState();
  const [show, setshow] = useState(false);
  const navigate = useNavigate();
  const [selectedChatbot, setSelectedChatbot] = useState(null);
  const url = "https://us2.sourcesoftsolutions.com:8065/static/";
  const userID = useSelector((state) => state?.user?.data?._id);

  console.log(userID, "userID")
  console.log(chatbotListing, "chatbotListing")


  const getChatBotListing = async () => {
    setIsLoading(true);


    try {
      await allApi({
        url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.get_all_chatbots}/${userID}`,

        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },

        formData: false,
      }).then((data) => {
        setchatbotListing(data?.data);
        setIsLoading(false);
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getChatBotListing();
  }, [userID])


  const handleClosepopup = () => {
    setshow(false);

  }

  const handleShowpopup = (chatbot) => {
    setSelectedChatbot(chatbot);
    setshow(true);

  }

  const copyToClipboard = () => {
    if (selectedChatbot) {
      const textToCopy = `<chat-bot platform_id="2d798e16-0170-4f32-90b8-569812585aab" user_id="${userID}" chatbot_id="${selectedChatbot?.chatbot_id}"></chat-bot><script src="https://sitemployee.sourcesoftsolutions.com/assets/images/chatbot/chat-bot-loader.js" defer></script>`;
      copy(textToCopy);
      toast.success("Copied to clipboard!");
    }
  };
  return (
    <>

      <main>
        <header>
          <h1 className="text-center text-dark " style={{ fontSize: "25px" }}
          >Chatbot Listing </h1>
          <div className="col d-flex justify-content-end align-items-center">
            <div className="navbar">
              <Link to="/create" className="nav-link"
              >Create Chatbot</Link>

            </div>
          </div>
        </header>

        <section className="training-materials">
          {/* <h2>Step 2: Train</h2> */}
          <table>
            <thead>
              <tr>
                <th>ChatBotID</th>
                <th>ChatBotColor</th>
                <th>Font</th>
                <th>BorderColor</th>
                <th>Icons</th>
                <th>frequency_penalty</th>
                <th>max_tokens</th>
                <th>avatar_video</th>
                <th>presence_penalty</th>
                <th>temperature</th>
                <th>top_p</th>
                <th>welcome_message</th>
                <th>Customize ChatBot</th>
                <th>View ChatBot</th>

              </tr>
            </thead>
            <tbody>
              {/* <!-- Table rows will be inserted here by JavaScript or server-side code --> */}
              {
                chatbotListing?.length > 0 && chatbotListing && chatbotListing?.map((ele) => (

                  <tr>
                    <td>{ele?.chatbot_id}</td>
                    <td>{ele?.colour}</td>
                    <td> {ele?.font}</td>
                    <td>{ele?.border_colour}</td>
                    <td><img className="customImage" src={url + ele?.icon} /></td>
                    <td>{ele?.frequency_penalty}</td>
                    <td>{ele?.max_tokens}</td>
                    <td>{ele?.hide_avatar_video}</td>
                    <td>{ele?.presence_penalty}</td>
                    <td>{ele?.temperature}</td>
                    <td>{ele?.top_p}</td>
                    <td>{ele?.welcome_message}</td>
                    <td><Link to={`/updatechatbot/${ele?.chatbot_id}`}><FaPencilAlt /></Link></td>

                    <td><Link to={`/trainingmaterial/${ele?.chatbot_id}`}><FaEye /></Link></td>

                    {/* <td><Link to=""><FaEye  onClick={() => handleShowpopup(ele)} /></Link></td> */}
                  </tr>
                ))
              }

            </tbody>
          </table>
          {IsLoading ? (
            <Oval
              visible={true}
              height="50"
              width="50"
              color="#4fa94d"
              ariaLabel="oval-loading"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          ) : (
            ""
          )}
        </section>
      </main>
      {show && selectedChatbot ?
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header justify-content-center">
                <h5 className="modal-title text-center">Copy ChatBot script </h5>
                <button type="button " className="btn-close position-absolute end-0 me-3" onClick={handleClosepopup} aria-label="Close">
                </button>
              </div>
              {/* <div className="flex items-center" style={{display: 'flex'}} >
          <pre className="bg-gray-100 rounded py-4 px-2 text-sm text-gray-800 overflow-x-auto" style={{ height: '200px', whiteSpace: 'pre-wrap' }}>
            &lt;chat-bot platform_id="2d798e16-0170-4f32-90b8-569812585aab" user_id={userID} chatbot_id={selectedChatbot.chatbot_id}&gt;&lt;/chat-bot&gt;&lt;script src="https://sitemployee.sourcesoftsolutions.com/assets/images/chatbot/chat-bot-loader.js" defer&gt;&lt;/script&gt;</pre
            ><div>
              
              <button className="flex gap-2 border-2 px-5 py-2 ml-4 rounded-lg text-themeGreen mr-4 hover:bg-green-50 border-themeGreen font-bold" onClick={copyToClipboard}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="ml-[-10px]">
                  <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path><path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1">
                    </path></svg>
                    Copy</button>
                    </div>
                    
                    </div> */}


              <div className="flex flex-col items-start" style={{ display: 'flex', flexDirection: 'column' }}>
                <pre className="bg-gray-100 rounded py-4 px-2 text-sm text-gray-800" style={{ whiteSpace: 'pre-wrap' }}>
                  &lt;chat-bot platform_id="2d798e16-0170-4f32-90b8-569812585aab" user_id={userID} chatbot_id={selectedChatbot.chatbot_id}&gt;&lt;/chat-bot&gt;
                  &lt;script src="https://sitemployee.sourcesoftsolutions.com/assets/images/chatbot/chat-bot-loader.js" defer&gt;&lt;/script&gt;
                </pre>
                <div>
                  <button className="flex gap-2 border-2 px-5 py-2 mt-4 rounded-lg text-themeGreen hover:bg-green-50 border-themeGreen font-bold" onClick={copyToClipboard}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="ml-[-10px]">
                      <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path><path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1">
                      </path></svg>
                    Copy
                  </button>
                </div>
              </div>

            </div>
          </div>

        </div>
        : ""
      }
    </>
  );
}

export default Chatbotlisting;
