import React from "react";
const TermAndCondition = () => {
  return (
    <>


<div class="hero-basic">
  <div class="hero-basic__bg hero-basic__bg--blue">
    <div class="bg-shapes"></div>
  </div>
  <div class="hero-basic__content">
    <h1>Terms And Conditions</h1>
    <p class="customizep">
    Welcome to ChirpChat. By accessing or using our chatbot service, you agree to comply with the following terms and conditions.
    </p>
    
  </div>
</div>

      <section className='terms'>
        <div className='container'>
          <div className='row'>
          <div className="col-md-12">
            {/* <h2 className="featuresh2 tabheading">Terms And Conditions</h2> */}
            <div className="heading-container">
              {/* <h1>Welcome to ChirpChat. By accessing or using our chatbot service, you agree to comply with the following terms and conditions.
              </h1> */}
            </div>
            {/* <p className=""></p><br /> */}
            
              <br /><h3 className="customisedh3">Acceptance of Terms:</h3><br />
              <p className="TandP"> By accessing or using ChirpChat, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our service.</p>
              <div className='priBox'>
                <h3 className="customisedh3">Use of Service</h3><br />
                <p className="TandP">ChirpChat is provided for the purpose of assisting businesses with customer engagement and operational efficiency. You may use the service only for lawful purposes and in accordance with these terms.</p>
              </div>
              <div className='priBox'>
                <h3 className="customisedh3">Intellectual Property</h3><br />
                <p className="TandP">All content, materials, and intellectual property rights associated with ChirpChat are owned by Grey Matterz Inc. You may not use, modify, or distribute any of the content without prior written consent.</p>
              </div>
              <div className='priBox'>
                <h3 className="customisedh3">Privacy Policy</h3><br />
                <p className="TandP">Our Privacy Policy governs the collection, use, and disclosure of personal information collected through ChirpChat. By using our service, you consent to the terms of our Privacy Policy.</p>
              </div>
              <div className='priBox'>
                <h3 className="customisedh3">Disclaimer of Warranties</h3><br />
                <p className="TandP">ChirpChat is provided "as is" and "as available" without warranties of any kind, whether express or implied. We do not warrant that the service will be uninterrupted, error-free, or free of viruses or other harmful components.</p>
              </div>
              <div className='priBox'>
                <h3 className="customisedh3">Limitation of Liability</h3><br />
                <p className="TandP">In no event shall ChirpChat be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of ChirpChat.</p>
              </div>
              <div className='priBox'>
                <h3 className="customisedh3">Governing Law</h3><br />
                <p className="TandP">These Terms of Service shall be governed by and construed in accordance with the laws of New Jersey, USA, without regard to its conflict of law provisions.</p>
              </div>
              <div className='priBox'>
                <h3 className="customisedh3">Changes to Terms</h3><br />
                <p className="TandP">ChirpChat reserves the right to modify or revise these Terms of Service at any time without prior notice. Your continued use of ChirpChat constitutes acceptance of the modified terms.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-4 text-center" >
        <div className="container heading-container">
          <h1>Please review our full Terms of Service carefully before using ChirpChat. If you have any questions or concerns, please contact us at info@chirpchat.ai</h1>
        </div>
      </section>
    </>
  )
}
export default TermAndCondition;