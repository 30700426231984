import React from 'react';

const BlogResourcesPage = () => {
    return (


        <>

<div class="hero-basic">
  <div class="hero-basic__bg hero-basic__bg--blue">
    <div class="bg-shapes"></div>
  </div>
  <div class="hero-basic__content">
    <h1>Blogs</h1>
    <p class="customizep">Welcome to the ChirpChat blog, your go-to resource for insights, tips, and best practices on AI-powered customer engagement, business growth, and more. Explore our latest articles, guides, and resources to stay informed and inspired</p>
    <div class="hero-basic__btns">
	    				
                   
					    </div>
  </div>
</div>

            <section className="whyChosefeatures" id="why"><div className="container">
              

                <div className="row pt-5">
                    <div className="col-lg-6">
                        <div className="whyBox_2">
                            <h3 className='customizeh1'><b>AI Insights</b></h3>
                            <p className='customizep'>Dive into the world of artificial intelligence with articles that explore the latest trends, developments, and applications in AI technology. Learn how AI is transforming industries, driving innovation, and reshaping the future of business.</p>
                        </div>
                    </div>
                    <div className="col-lg-6"><div className="whyBox_2">
                        <h3 className='customizeh1'><b>Customer Engagement</b></h3>
                        <p className='customizep'> Discover strategies for enhancing customer engagement and satisfaction with AI-powered chatbots. Explore topics such as personalized interactions, proactive engagement, and omnichannel communication to delight customers and drive loyalty.</p>
                    </div>
                    </div>
                    <div className="col-lg-6"><div className="whyBox_2">
                        <h3 className='customizeh1'><b>Business Growth</b></h3>
                        <p className='customizep'>Unlock the secrets to business growth and success with practical tips and advice from industry experts. Explore topics such as marketing strategies, sales techniques, and operational efficiency to maximize your business potential.</p></div>
                    </div>
                    <div className="col-lg-6">
                        <div className="whyBox_2">
                            <h3 className='customizeh1'><b>Case Studies</b></h3>
                            <p className='customizep'> Gain inspiration from real-life success stories of businesses that have leveraged ChirpChat to achieve remarkable results. Learn how businesses across various industries have transformed their customer engagement and operations with AI technology.</p></div></div><div className="col-lg-6"><div className="whyBox_2">

                                <h3 className='customizeh1'><b>How-to Guides</b></h3>
                                <p className='customizep'>Get step-by-step instructions and tutorials on how to get the most out of ChirpChat. Learn how to integrate the chatbot with your website, customize its responses, analyze data insights, and more.</p>
                            </div>
                        <div className="whyBox_2">
                            <h3 className='customizeh1'><b>Industry Insights</b></h3>
                            <p className='customizep'> Stay ahead of the curve with industry-specific insights and analysis. Explore how AI-powered chatbots are revolutionizing healthcare, education, retail, finance, and other sectors, and discover opportunities for innovation and growth.</p>
                        </div></div><div className="col-lg-6"><div className="whyBox_2">
                            <h3 className='customizeh1'><b>Expert Interviews</b></h3>
                            <p className='customizep'>Gain valuable insights from industry experts and thought leaders through exclusive interviews and Q&A sessions. Learn from their experiences, perspectives, and advice on leveraging AI technology for business success.</p>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <section className="mt-4 text-center" >
                <div className='heading-container'>
                    <h1>Explore our blog archive and start your journey <br/>towards AI-powered customer engagement and business growth today.</h1>
                </div>
            </section>
        </>

    )
};

export default BlogResourcesPage;
