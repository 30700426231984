import React, { useState } from 'react';
import { SetpopupReducerData } from '../Store/reducer/PopupReducer';
import { useDispatch, useSelector } from 'react-redux';
import { allApi } from '../apiwrapper/api';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa6";
import apiURL from '../Store/Action/api-url';



const ChangePasswordModel = () => {
  const [showModal, setShowModal] = useState(true);
 
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState("");
  const Email=useSelector((state)=>state?.PopupReducer?.modal?.email);




  const dispatch = useDispatch();
  
  const navigate=useNavigate();
  const handleClosepopup = () => {
    dispatch(SetpopupReducerData({ modalType: "", showModal: false }));
  };
  const handleShowSignUp = () => {
    dispatch(
      SetpopupReducerData({
        modalType: "Signup",
        showModal: true,
      })
    );
  
};
  const handleSubmit = async(e) => {
    e.preventDefault();

    try {
      await allApi({
          url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.newchangepassword}`,

          method: "POST",
          body: {
            Email_ID: Email,
            New_Password: password,
            Confirm_New_Password: confirmPassword   
              },
          headers: {
              'Content-Type': 'application/json'
          },

          formData: false,
      }).then((data) => {
        if(data?.status)
        {

            handleClosepopup();
            toast.success(data?.message);
        }
        toast.error(data?.error);
      });
  } catch (error) {
  } finally {
  }


  };


  return (
    <>
      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header justify-content-center">
                <h5 className="modal-title">Change Password</h5>
                <button type="button " className="btn-close position-absolute end-0 me-3" onClick={handleClosepopup} aria-label="Close">
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                 
                  <div className="form-group">
                    <label htmlFor="password"> New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary text-primary">submit </button>
                  <button type="button" className="btn btn-secondary text-primary" onClick={handleClosepopup}>Close</button>
                </div>
                </form>

          
         
            </div>
          </div>
         
        </div>
      )}
    </>
  );
};

export default ChangePasswordModel;
