import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { allApi } from "../apiwrapper";
import copy from "copy-to-clipboard";
import { Oval } from "react-loader-spinner";
import FileAndTextInput from "./Chatbot/FileAndTextInput"
import { toast } from "react-toastify";
import apiURL from "../Store/Action/api-url";
import { Button } from "react-bootstrap";


function TrainingMaterial() {
  const [activeTab, setActiveTab] = useState("fileUpload");
  const [urlData, seturlData] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [files, setFiles] = useState([]);
  const [update, setUpdate] = useState(false);


  const { id } = useParams();

  const userID = useSelector((state) => state?.user?.data?._id);


  console.log(id, "chatbotidchatbotid");
  console.log(urlData, "urlDataurlData");

  const getUrlListing = async () => {
    try {
      const response = await allApi({
        url: `${process.env.REACT_APP_API_BACKEND_URL}/get_urls/${userID}/${id}`,
        // url: `http://192.168.20.194:5000/get_urls/${userID}/${id}`,
        // url: `https://us2.sourcesoftsolutions.com:8031/get_urls/${userID}/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        formData: false,
      });

      const data = response?.data;
      seturlData(response?.data);
      console.log(data, "fetchurl");
    } catch (error) { }
  };

  const UpdateUrl = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formDataPost = new FormData();
    formDataPost.append('userID', userID);
    formDataPost.append('chatbotID', id);
    formDataPost.append('urls[]', websiteUrl)

    files.length > 0 && files.map((fil) => {
      formDataPost.append('files[]', fil)
    });

    try {

      await allApi({
        url: `${process.env.REACT_APP_API_BACKEND_OTHER_URL}${apiURL.update_chatbot}`,
        //  url:`http://192.168.20.89:8000/update_chatbot_test`,
        method: "PUT",
        body: formDataPost,
        formData: true,
      }).then((data) => {
        toast.success(data?.payload?.result);
        getUrlListing();
        setWebsiteUrl("");
        setUpdate(true);
        setIsLoading(false);
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
      setWebsiteUrl("");
    }
  };
  useEffect(() => {
    getUrlListing();
  }, [userID]);


  const copyToClipboard = () => {
    if (id) {
      const textToCopy = `<chat-bot platform_id="2d798e16-0170-4f32-90b8-569812585aab" user_id="${userID}" chatbot_id="${id}"></chat-bot><script src="${process.env.REACT_APP_CHATBOT_LOADER_SCRIPT}" defer></script>`;
      copy(textToCopy);
      toast.success("Copied to clipboard!");
    }
  };

  const handleTextChange = (text) => {
    setWebsiteUrl(text);
  };

  const handleFileChange = (files) => {
    setFiles(files);
  };
  return (
    <>
      <main>

        <section className="training-materials">
          <div className="col d-flex justify-content-center align-items-center">
            <h1 className="text-center text-dark " style={{ fontSize: "25px" }}> Add Chatbot Content </h1>
          </div>
          <div className="col d-flex justify-content-center align-items-center">
            <p>Follow just simple steps and create your AI powered chatbot. And apply it on your website in just some clicks.</p>
          </div>
        </section>
        <section className="training-materials">
          <div className="dev">
            <form id="">
              <div className="row d-flex justify-content-center">
                <div className="col">
                  <label for="website-url">Your Website</label>
                  <FileAndTextInput
                    onTextChange={handleTextChange}
                    onFileChange={handleFileChange}
                  />
                </div>


                <br />
                {isLoading ? (
                  <Oval
                    visible={true}
                    height="50"
                    width="50"
                    color="#4fa94d"
                    ariaLabel="oval-loading"
                    wrapperStyle={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-center mt-4">
                  <Button
                    type="button"
                    id="fetch-links"
                    onClick={(e) => {
                      UpdateUrl(e);
                    }}
                  >
                    Process URL
                  </Button>
                  <br />
                </div>

              </div>
            </form>
          </div>


        </section>
        <section className="training-materials">
          <table>
            <thead>
              <tr>
                <th>ChatBotId</th>
                <th>File</th>
                <th>URL</th>
              </tr>
            </thead>
            <tbody>
              {urlData?.length > 0 &&
                urlData &&
                urlData?.map((ele) => (
                  <tr>
                    <td>{ele?.chatbot_id}</td>
                    <td>{ele?.files}</td>
                    <td>
                      {urlData && ele?.urls.map((url, index) => (
                        <div key={index}>{url}</div>
                      ))}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </section>
        {update &&
          update ?
          (

            <section className="upload-area">
              <p>{`To embed your chatbot onto your website, paste this snippet into your website's HTML file, right before the closing body tag  </body> .For other website management systems, book a meeting with us.`}</p>
              <div className="flex flex-col items-start" style={{ display: 'flex', flexDirection: 'column' }}>
                <pre className="bg-gray-100 rounded py-4 px-2 text-sm text-gray-800" style={{ whiteSpace: 'pre-wrap' }}>
                  &lt;chat-bot platform_id="2d798e16-0170-4f32-90b8-569812585aab" user_id={userID} chatbot_id={id}&gt;&lt;/chat-bot&gt;
                  &lt;script src="{process.env.REACT_APP_CHATBOT_LOADER_SCRIPT}" defer&gt;&lt;/script&gt;
                </pre>
                <div>
                  <button className="flex gap-2 border-2 px-5 py-2 mt-4 rounded-lg text-themeGreen hover:bg-green-50 border-themeGreen font-bold" onClick={copyToClipboard}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="ml-[-10px]">
                      <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path><path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1">
                      </path></svg>
                    Copy
                  </button>
                </div>
              </div>
            </section>
          ) : ""}

      </main>
    </>
  );
}

export default TrainingMaterial;
