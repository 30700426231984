import React from "react";
const HowITWorks = () => {
  return (
    <>


<div class="hero-basic">
  <div class="hero-basic__bg hero-basic__bg--blue">
    <div class="bg-shapes"></div>
  </div>
  <div class="hero-basic__content">
    <h1>How It Works</h1>
    <p class="customizep">
    Curious about how ChirpChat can revolutionize your customer engagement and business operations? Let us walk you through the simple yet powerful process
    </p>
    
  </div>
</div>
    
  




      <section className="whyChosefeatures" id="why"><div className="container">
        {/* <h2 className=" featuresh2 tabheading">How It Works</h2> */}
        <div className="heading-container">
          {/* <h1>Curious about how ChirpChat can revolutionize your customer engagement and business operations? Let us walk you through the simple yet powerful process
          </h1> */}
        </div>
        {/* <h3 className="text-center">Curious about how ChirpChat can revolutionize your customer engagement and business operations? Let us walk you through the simple yet powerful process</h3> */}

        <div className="row pt-5">
          <div className="col-lg-6">
            <div className="whyBox_2">
              <h3 className="customizeh1"><b>Integration</b></h3>
              <p className="customizep">The journey begins with seamless integration. Our team works closely with yours to integrate ChirpChat with your existing business systems and data sources. This includes your website, Google Drive, calendars, and other platforms where your business information resides.</p>
            </div>
          </div>
          <div className="col-lg-6"><div className="whyBox_2">
            <h3 className="customizeh1"><b>Configuration</b></h3>
            <p className="customizep">Once integrated, we customize ChirpChat to align with your brand voice, objectives, and customer preferences. From defining response patterns to setting up scheduling parameters, we tailor the chatbot's functionality to meet your specific needs.</p>
          </div>
          </div>
          <div className="col-lg-6"><div className="whyBox_2">
            <h3 className="customizeh1"><b>Deployment</b></h3>
            <p className="customizep">With everything in place, it's time to deploy ChirpChat on your website or preferred communication channels. Our team ensures a smooth and hassle-free deployment process, minimizing downtime and maximizing impact.</p></div>
          </div>
          <div className="col-lg-6">
            <div className="whyBox_2">
              <h3 className="customizeh1"><b>Engagement</b></h3>
              <p className="customizep"> Once deployed, ChirpChat springs into action, engaging with your customers in real-time. Whether they have questions about your products, need assistance with scheduling, or require access to documents, our chatbot is there to help.</p>
            </div>
          </div>
          <div className="col-lg-6"><div className="whyBox_2">
            <h3 className="customizeh1"><b>Analysis</b></h3>
            <p className="customizep">As interactions unfold, ChirpChat collects valuable data and insights into customer behavior, preferences, and trends. Our analytics tools provide you with actionable insights, empowering you to refine your marketing strategies and improve customer engagement.</p>
          </div>
            <div className="whyBox_2">
              <h3 className="customizeh1"><b>Optimization</b></h3>
              <p className="customizep"> We believe in continuous improvement. Our team monitors ChirpChat performance closely, identifying areas for optimization and refinement. Through ongoing updates and enhancements, we ensure that your chatbot remains at the forefront of AI technology.</p>
            </div>
          </div>
          <div className="col-lg-6"><div className="whyBox_2">
            <h3 className="customizeh1"><b>Support</b></h3>
            <p className="customizep">Throughout the process, our dedicated support team is here to assist you every step of the way. Whether you have questions, need technical assistance, or want to explore additional features, we're just a message or call away.</p>
          </div>
          </div>
        </div>
      </div>
      </section>
      <section className="mt-4 text-center" >
        <div className="heading-container ">
          <h1>Ready to see ChirpChat in action?<br/> Contact us today to schedule a demo and experience the future of customer engagement firsthand.</h1>
        </div>
      </section>
    </>
  )
}

export default HowITWorks;