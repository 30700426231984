import React, { useState } from "react";

import Accordion from 'react-bootstrap/Accordion';




const FAQ = () => {


  return (

    <>
    <div class="hero-basic">
  <div class="hero-basic__bg hero-basic__bg--blue">
    <div class="bg-shapes"></div>
  </div>
  <div class="hero-basic__content">
    <h1>FAQs</h1>
    <p class="customizep">
    Got questions about ChirpChat? We've got answers. Check out our FAQs below to find answers to commonly asked questions</p>
    
  </div>
</div>
      <section className='terms'>
        <div className='container'>
          <div className='row'>
            {/* <h2 className="featuresh2 tabheading">FAQs</h2> */}
            <div className="heading-container">
              {/* <h1>Got questions about ChirpChat? We've got answers. Check out our FAQs below to find answers to commonly asked questions
              </h1> */}
            </div>
            {/* <h4></h4><br /> */}
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">

              <div className="faqStyle">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="customizeh1">What is ChirpChat?</Accordion.Header>
                    <Accordion.Body className="customizeli">
                      ChirpChat is an advanced AI-powered chatbot service designed to revolutionize customer engagement and business operations. It acts as a personalized assistant, seamlessly integrating with your business systems and data sources to provide intelligent responses, proactive engagement, and valuable insights.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="customizeh1">How does ChirpChat work?</Accordion.Header>
                    <Accordion.Body className="customizeli">
                      ChirpChat works by leveraging generative AI technology to understand and respond to customer queries with unparalleled accuracy and relevance. It integrates with your website, documents, calendars, and other data sources to provide contextual responses and personalized assistance.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="customizeh1">What can ChirpChat do?</Accordion.Header>
                    <Accordion.Body className="customizeli">
                      ChirpChat can do much more than just answer questions. It can schedule meetings, manage appointments, retrieve documents, provide product recommendations, and deliver insights into customer behavior and preferences. It's like having a personal assistant for your business.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header className="customizeh1" >Is ChirpChat  customizable?</Accordion.Header>
                    <Accordion.Body className="customizeli" >
                      Yes, ChirpChat is highly customizable to fit your business needs and branding. You can customize its responses, branding, functionality, and integration with other systems to align with your brand voice and objectives.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header className="customizeh1">How do I integrate ChirpChat with my website?</Accordion.Header>
                    <Accordion.Body className="customizeli">
                      Integrating ChirpChat with your website is simple and straightforward. Our team will work closely with yours to ensure a smooth integration process, providing guidance and support every step of the way.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header className="customizeh1">What kind of analytics and insights does ChirpChat provide?</Accordion.Header>
                    <Accordion.Body className="customizeli">
                      ChirpChat provides comprehensive analytics and insights into customer interactions, website traffic, and engagement metrics. You can gain valuable insights into customer behavior, preferences, and trends to inform your marketing strategies and business decisions.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header className="customizeh1">Is ChirpChat suitable for businesses of all sizes?</Accordion.Header>
                    <Accordion.Body className="customizeli">
                      Yes, ChirpChat is suitable for businesses of all sizes, from startups to large enterprises. Whether you're a small business looking to streamline customer support or a large enterprise seeking advanced AI capabilities, ChirpChat can be customized to meet your specific needs.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header className="customizeh1">How do I get started with ChirpChat?</Accordion.Header>
                    <Accordion.Body className="customizeli">
                      Getting started with ChirpChat is easy. Simply contact us to schedule a demo or request more information, and one of our representatives will be happy to assist you.
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-4 text-center" >
        <div className="heading-container">
          <h1>Have more questions? Feel free to contact us for further assistance</h1>
        </div>
      </section>

    </>
  );
};

export default FAQ;
