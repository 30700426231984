import React, { useState } from "react";
import { allApi } from "../apiwrapper/api";
import { toast } from "react-toastify";
import apiURL from "../Store/Action/api-url";
import { PopupButton } from "react-calendly";

function ContactUs() {
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    message: "",
    phoneNumber: "",
    companyName: ""
  });

  const handleChange = (e) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await allApi({
        // url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.contact}`,
        url:' http://192.168.2.171:1000/contact',
        method: "POST",
        body: {
          name: inputData?.name,
          email: inputData?.email,
          message: inputData?.message,
          conatct_no: inputData?.phoneNumber,
          company_name: inputData?.companyName
        },
        headers: {
          "Content-Type": "application/json",
        },

        formData: false,
      }).then((data) => {
        toast.success(data?.message);

        if (data?.message) {
          setInputData({
            name: "",
            email: "",
            message: "",
            phoneNumber: "",
            companyName: ""
          });

        }
      });
    } catch (error) {
    } finally {

      setInputData({
        name: "",
        email: "",
        message: "",
        phoneNumber: "",
        companyName: ""
      });
    }
  };

  return (
    <>

<div class="hero-basic">
  <div class="hero-basic__bg hero-basic__bg--blue">
    <div class="bg-shapes"></div>
  </div>
  <div class="hero-basic__content">
    <h1>Contact Us</h1>
    <p class="customizep">
    Have questions or inquiries about ChirpChat? We are here to
                help! Contact us using the information below, and one of our
                representatives will get back to you as soon as possible
    </p>
    
  </div>
</div>
    <div className="text-center">
    <PopupButton
        url="https://calendly.com/donatedotin"
        rootElement={document.getElementById("root")}
        styles={{
          backgroundColor: "#4CAF50",
          color: "white",
          padding: "15px 32px",
          textAlign: "center",
          textDecoration: "none",
          display: "inline-block",
          fontSize: "16px",
          // margin: "4px 2px",
          cursor: "pointer",
          borderRadius: "20px",
          border: "none",
        }}
        // prefill={{
        //   email: "test@test.com",
        //   firstName: "Jon",
        //   lastName: "Snow",
        //   name: "Jon Snow",
        //   smsReminderNumber: "+1234567890",
        //   guests: ["janedoe@example.com", "johndoe@example.com"],
        //   customAnswers: {
        //     a1: "a1",
        //     a2: "a2",
        //     a3: "a3",
        //     a4: "a4",
        //     a5: "a5",
        //     a6: "a6",
        //     a7: "a7",
        //     a8: "a8",
        //     a9: "a9",
        //     a10: "a10",
        //   },
        //   date: new Date(Date.now() + 86400000),
        // }}
        text="Schedule meeting by Calendly!"
      />
    </div>
      {/* <section className="whyChosefeatures" id="why">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 heading-container">
              
            </div>
            
          </div>
        
        </div>
      </section> */}
      <div className="contact-container">
        {/* <div className="header"> */}
        <p className="customizep">
          Connect with ChatBot: We're Here to Help and Listen!
          <br/>Let's get the Show on the Road. Contact us Today!

        </p>
       
      
        <div className="form-container-contact">
          <h1>
            <b>Request a Demo:</b>
            <br />
          </h1>
          {/* <h1>Interested in experiencing the power of ChirpChat firsthand? Fill out the form below to request a personalized demo, and one of our representatives will be in touch to schedule a convenient time for you.</h1> */}
          <form className="dev">
            <div className="row">
              <div className="">
                <label htmlFor="name" className="d-flex align-items-end">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your name.."
                  value={inputData?.name}
                  onChange={handleChange}
                />
              </div>

              <div className="">
                <label htmlFor="email" className="d-flex align-items-end">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your email.."
                  value={inputData?.email}
                  onChange={handleChange}
                />
              </div>
              <div className="">
                <label htmlFor="phoneNumber" className="d-flex align-items-end">
                  Phone Number
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Your phone number.."
                  value={inputData?.phoneNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="">
                <label htmlFor="companyName" className="d-flex align-items-end">
                  Company Name
                </label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  placeholder="Your company name.."
                  value={inputData?.companyName}
                  onChange={handleChange}
                />
              </div>
              <div className="">
                <label htmlFor="message" className="d-flex align-items-end">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Write something.."
                  value={inputData.message}
                  rows={5}
                  onChange={handleChange}
                />
              </div>
            </div>

            <input type="submit" value="Submit" onClick={handleSubmit} />
          </form>
        </div>
      </div>
  


      <section className="top-section">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-5">
                        <div className="heading-container">
                                <h1 className="customizeh1">Support:</h1>
                                <p className="lead"> For technical assistance or support inquiries, please visit our
            Customer Support or contact our support team directly at
            support@chirpchat.ai  or 732-354-1541.</p> 
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="heading-container">
                                <h1 className="customizeh1">Business Inquiries:</h1>
                                <p className="lead"> For partnership opportunities, media inquiries, or other
            business-related matters, please contact our business development
            team at info@chirpchat.ai or 732-354-1541.</p>  
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="my-4">
        <div className="container">
         
          <p className="customizep">
            We look forward to hearing from you and assisting you with your
            chatbot needs!
          </p>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
