import React, { useEffect } from "react";
import { allApi } from "../apiwrapper/api";

import apiURL from "../Store/Action/api-url";
import { toast } from "react-toastify";

const Payment = () => {


  const addpayementgatway = async () => {

    try {
      await allApi({
        url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.payment}`,
        // url:"http://192.168.20.240:5000/create-checkout-session",
        method: "POST",
        body: {
          product_name: 'Custom T-shirt',
          price: 25,
          quantity: 2,
        },
        headers: {
          "Content-Type": "application/json",
        },
        formData: false,
      }).then((data) => {

        console.log(data, "sad");
        window.location.href = data.url;
      });


    } catch (error) {
      console.error(error, "eeeerrr");

    } finally {
    }


  }






  return (
    <>
      <section className="whyChosefeatures" id="why"><div className="container">
        <h2 className="featuresh2 tabheading">Payment Plans</h2>
        <h3 className="text-center">Unlock the power of ChirpChat with our flexible pricing plans designed to fit businesses of all sizes</h3></div>
      </section>
      <div className="pricecontainer">

        <div className="price-row d-flex justify-content-center">
          <div className="price-col col-sm-5">
            <p>Basic</p>
            <hr />
            <h3>
              <span>Pricing available upon request</span>
            </h3>
            <p>Ideal for small businesses and startups looking to get started with AI-powered customer engagement. Features include intelligent responses, basic integration with business systems, and essential support. </p>
            <button onClick={addpayementgatway}>Payment</button>

          </div>

        </div>
      </div><br />


    </>
  )
}

export default Payment;