import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import TextareaAutosize from 'react-textarea-autosize';
import './FileAndTextInput.css';
import { Send } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';

function FileAndTextInput({ onTextChange, onFileChange }) {
    const [files, setFiles] = useState([]);
    const [inputText, setInputText] = useState("");

    useEffect(() => {
        return () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
        };
    }, [files]);

    const onDrop = useCallback((acceptedFiles) => {
        const newFiles = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
        if (onFileChange) {
            onFileChange([...files, ...newFiles]);
        }
    }, []);

    const removeFile = (fileName) => {
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
        URL.revokeObjectURL(fileName.preview);
        if (onFileChange) {
            const updatedFiles = files.filter(file => file.name !== fileName);
            onFileChange(updatedFiles);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        noClick: true
    });

    const thumbs = files.map(file => (


        // <div key={file.name} className="thumb col-sm-4">
        //     <div className="thumb-inner thumb-image">
        //         {/* <div className="thumb-image"> */}
        //         {file?.name}
        //         {/* </div> */}
        //         {/* <div className="thumb-details">
        //             <span>{file?.name}</span>
        //         </div> */}
        //         <button onClick={() => removeFile(file.name)} className="thumb-remove"><ClearIcon /></button>
        //     </div>
        // </div>

        <div key={file.name} className="col-sm-4">
            <div key={"index"} className="input-group">
                {file?.name}
                <span className="icon-clear">
                    <button onClick={() => removeFile(file.name)} className=""><ClearIcon /></button>
                </span>
            </div>
        </div>


    ));

    const handleTextChange = (event) => {
        // setInputText(event.target.value);
        setInputText(event.target.value);
        if (onTextChange) {
            onTextChange(event.target.value);
        }
    };

    return (
        <div className="fileandtextinput">
            <div className='dropcontainer' {...getRootProps({ className: 'dropzone dropcontainer' })}>
                <input {...getInputProps()} />
                <TextareaAutosize
                    placeholder="Type your text here..."
                    value={inputText}
                    onChange={handleTextChange}
                    className="text-area"
                />
                {/* <aside className="thumbs-container">
                    {thumbs}
                </aside> */}
                <div className='container-fluid'>
                    <div className='row'>
                        {thumbs}
                    </div>
                </div>


            </div>
            {/* <button className='buttonContainer'><Send /></button> */}
        </div>
    );
}

export default FileAndTextInput;
