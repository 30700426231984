



import React, { useState } from "react";
import apiURL from "../Store/Action/api-url";
import { API, allApi } from "../apiwrapper";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";

function Marketing({ category = {}, setCategory }) {
  const [urls, setUrls] = useState(['']);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [source, setSource] = useState('');
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const handleURLChange = (index, value) => {
    const newUrls = [...urls];
    newUrls[index] = value;
    setUrls(newUrls);
  };

  const addURLField = () => {
    setUrls([...urls, '']);
  };







  const processURLs = async () => {
    setLoading(true);
    try {
        const response = await fetch(`http://192.168.20.89:5000/genrate_content`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json', 
            },
            body: JSON.stringify({
                urls: urls, 
            }),
        });
        
        const data = await response.json(); 
        if (data) {
          console.log(data?.payload?.result,"ddadaddadaddadaddad");
          //  setAnswer(data?.payload?.result);
           setAnswer(Object.values(data?.payload?.result).join(' '));

            setLoading(false);
        } else {
        }
    } catch (error) {
        console.error("Error:", error);
        setLoading(false);

    } finally {
    }
};
// Component to render text with line breaks
const RenderTextWithLineBreaks = ({ text }) => (
  text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))
);

  return (
    <>

      <h2 className="text-center text-black mb-4 customizeh1 mt-4">Get Data From  Url </h2>
      <div className="research-tool">
        <div className="urls-input">
          <h2 className="text-black"> ADD  URLs</h2>
          {urls.map((url, index) => (
            <div key={index}>
              <input
                type="text"
                value={url}
                onChange={(e) => handleURLChange(index, e.target.value)}
                placeholder={`URL ${index + 1}`}
              />
              {/* {index === urls.length - 1 && urls.length < 5 && (
                <div className="input-group-append">
                  <button className="btn btn-outline-secondary" onClick={addURLField}>Add More URLs</button>
                </div>
              )} */}
            </div>
          ))}

          <div className="text-right">
            <div className="loader-button-container" style={{ position: 'relative', display: 'inline-block' }}>

              {loading ? (

                <Oval
                  visible={true}
                  height="50"
                  width="50"
                  color="#4fa94d"
                  ariaLabel="oval-loading"
                  wrapperStyle={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />

              ) : <button className="btn btn-primary text-end" onClick={processURLs} disabled={loading} style={{ opacity: loading ? 0.5 : 1, backgroundColor: loading ? '#d3d3d3' : '', color: loading ? '#a9a9a9' : '' }}>
                Process URLs
              </button>}
            </div>
          </div>

        </div>

        <div className="qa-section">
          {/* <h2 className="text-black">URL Research Tool</h2> */}
          {/* <div className="question">
            <label>Question:</label>
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                value={question}
                onChange={handleQuestionChange}
                onKeyDown={(e)=>{
                  if (e.key === 'Enter') {
                    handleKeyDown(e);
                  }
                }}
                style={{ paddingRight: '25px' }}
              />
              <i
                className="fa fa-arrow-up"
                aria-hidden="true"
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)'
                }}
                onClick={handleKeyDown}

              ></i>
            </div>


          </div> */}
          {isloading ? (
              <Oval
                visible={true}
                height="50"
                width="50"
                color="#4fa94d"
                ariaLabel="oval-loading"
                wrapperStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              /> ): (
          <div className="answer ">
            
            <h3 className="text-black customizeh1">Content</h3>
            {/* <p> {answer}</p> */}
            <RenderTextWithLineBreaks text={answer} />

            
  
           
          </div>
           )}
        </div>
      </div>
    </>


  );
}

export default Marketing;
