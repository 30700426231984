import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};
const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetched: (state, { payload }) => {
      state.data = payload.data;
    },
  },
});

const { fetched } = user.actions;

export default user.reducer;

export const SetUserData = (data) => async (dispatch) => {
  if (!data) throw Error("missing Data parameter");
  dispatch(fetched({ data }));
};
