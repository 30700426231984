import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { SetpopupReducerData } from '../Store/reducer/PopupReducer';
const ProtectedRoute = ({ userId, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId) {
      dispatch(
        SetpopupReducerData({
          modalType: "Signup",
          showModal: true,
        })
      );
    }

  }, [userId, dispatch]);

//   if (!userId) {
//     return <Navigate to="/signup" />;
//   }

//   return children;
};

export default ProtectedRoute;
