import React, { useEffect, useState } from "react";
import { SetpopupReducerData } from "../Store/reducer/PopupReducer";
import { useDispatch } from "react-redux";
import { allApi } from "../apiwrapper/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa6";
import { useGoogleLogin } from "@react-oauth/google";
import axios from 'axios';
import apiURL from "../Store/Action/api-url";
import { SetUserData } from "../Store/reducer/user";

const Signup = () => {
  const [showModal, setShowModal] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [user, setUser] = useState(null);

  

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClosepopup = () => {
    dispatch(SetpopupReducerData({ modalType: "", showModal: false }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.success("Passwords do not match!");
      return;
    }

    try {
      await allApi({
        url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.Signup}`,

        method: "POST",
        body: {
          first_name: firstName,
          last_name: lastName,
          Email_ID: email,
          Password: password,
          Confirm_password: confirmPassword,
        },
        headers: {
          "Content-Type": "application/json",
        },

        formData: false,
      }).then((data) => {
        // console.log(data,"sad");
        if(data?.status=="true")
        {

          toast.success(data?.message);
          handleClosepopup();
          navigate("/");
        }
        else
        {
        toast.error(data?.message);
        }
      });
    } catch (error) {
      toast.error(error);

    } finally {
    }
  };

 

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => setUser(tokenResponse),
  });
  
  const fetchData = async () => {
    try {
      const userInfoRes = await axios.get(
        `https://www.googleapis.com/oauth2/v2/userinfo?access_token=${user.access_token}`,
        {
          headers: {
            // Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        }
      );
  
      const obj = userInfoRes?.data;
  
      console.log(obj);
  
      const payload = {
        first_name: obj?.given_name,
        last_name: obj?.family_name,
        Email_ID: obj?.email,
        Image: obj?.picture,
        // GId: obj?.id,
        Method: "GOOGLE",
      };
      console.log(payload, "data");
  
       const response = await allApi({
        url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.sociallogin}`,

        method: "POST",
        body: payload,
        headers: {
          'Content-Type': 'application/json'
      },
        formData: false,
      });
  
      if (response?.status) {
  
        toast.success(response.message);
        dispatch(SetUserData(response?.data));
        navigate('/dashboard');

        handleClosepopup();
  
      }
  
    }
    catch (err) {
      throw err;
    }
  };
  
  
  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  return (
    <>
      {showModal && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header justify-content-center">
                <h5 className="modal-title">Sign Up</h5>
                <button
                  type="button "
                  className="btn-close position-absolute end-0 me-3"
                  onClick={handleClosepopup}
                  aria-label="Close"
                >
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary text-primary"
                  >
                    Sign Up
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary text-primary"
                    onClick={handleClosepopup}
                  >
                    Close
                  </button>
                </div>
                <div className="orWith my-2 text-center">Or Continue with</div>
                <div className="socalLogin my-2">
                  <button
                    className="gmBtn"
                     onClick={() => googleLogin()}
                  >
                    <span>
                      <FcGoogle />
                    </span>{" "}
                    Continue with Google
                  </button>
                  <button className="faBtn">
                    <span>
                      <FaFacebookF />
                    </span>{" "}
                    Continue with Facebook
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
