import React from "react";
const About = () => {
  return (
    <>
      {/* <section className="top-section">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <h1 className="featuresh2 ">About Us</h1>
            <div className="col-md-6 ">
              <div className="heading-container">

             
              <h1 className="customeh1new">Welcome to ChirpChat, your trusted partner in revolutionizing customer engagement and business growth through advanced AI technology.</h1>
              <p className="lead"><b>At ChirpChat,</b> we're passionate about empowering businesses with cutting-edge solutions that go beyond traditional chatbots. Our team of experts combines expertise in generative AI, data analytics, and customer engagement to deliver a chatbot service that's not just intelligent but transformative.</p>
              <p className="lead"><b>Our Mission:</b> To redefine the essence of customer interaction by providing businesses with an AI-powered chatbot that acts as a personalized assistant, seamlessly integrating with their existing systems and processes.</p>
            </div>
            </div>
            <div className="col-md- 5 ">
              <img src="images/image_1.jpg" alt="LLM Graphic" className="rounded-4  img-fluid" />
            </div>
          </div>
        </div>
      </section> */}


      <section className="top-section">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                       
                        <div className="col-md-6">
                            <div className="heading-container">
                                <h1 className="featuresh2">About Us</h1>
                                <p className="lead"><b>At ChirpChat,</b> we're passionate about empowering businesses with cutting-edge solutions that go beyond traditional chatbots. Our team of experts combines expertise in generative AI, data analytics, and customer engagement to deliver a chatbot service that's not just intelligent but transformative.</p>
              <p className="lead"><b>Our Mission:</b> To redefine the essence of customer interaction by providing businesses with an AI-powered chatbot that acts as a personalized assistant, seamlessly integrating with their existing systems and processes.</p>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <img src="images/image_1.jpg" alt="LLM Graphic" className="rounded-4 img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

      <section className="whyChose" id="why"><div className="container">
        <h2 className="customeh1new">What Sets Us Apart?</h2>
        <div className="row pt-5">
          <div className="col-lg-6">
            <div className="whyBox_2">
              <h1 className="text-center customizeh1"><b>Advanced AI Technology</b></h1>
              <p className="customizep">Our chatbot service is built on generative AI technology, enabling it to understand and respond to queries with unmatched accuracy and precision.</p>
            </div>
          </div>
          <div className="col-lg-6"><div className="whyBox_2">
            <h1 className="text-center customizeh1"><b>Integration Capabilities</b></h1>
            <p className="customizep">We don't just answer questions; we integrate with your business systems, including website data, documents, calendars, and more, to provide comprehensive and contextual responses.</p>
          </div>
          </div>
          <div className="col-lg-6"><div className="whyBox_2">
            <h1 className="text-center customizeh1"><b>Personalized Assistance</b></h1>
            <p className="customizep">Think of our chatbot as your personal assistant, capable of scheduling meetings, retrieving documents, and providing insights tailored to your business needs.</p></div>
          </div><div className="col-lg-6"><div className="whyBox_2">
            <h1 className="text-center customizeh1"><b>Analytics and Insights</b></h1>
            <p className="customizep"> Gain valuable insights into your website visitors, customer behavior, and web traffic with our analytics tools. Use this data to refine your marketing strategies, personalize communications, and increase revenue.</p></div></div><div className="col-lg-6"><div className="whyBox_2">
              <h1 className="text-center customizeh1"><b>Exceptional Support</b></h1>
              <p className="customizep">Our dedicated team is committed to providing exceptional support and guidance every step of the way, ensuring that you get the most out of our chatbot service.</p>
            </div>
          </div>
        </div>
      </div>
      </section>
      <section className=" text-center " >
        <div className="heading-container ">
          <h1>Join the ChirpChat  revolution today and unlock the ful<br/>l potential of your business. Contact us to schedule a demo<br />
            and discover how our AI-powered chatbot can transform your customer <br/>engagement and drive business growth.</h1>
        </div>
      </section>
    </>
  )
}

export default About;