import React from "react";
const CaseStudies = () => {
  return (
    <>
      <section className="whyChosefeatures" id="why"><div className="container">
        <h2 className="featuresh2 tabheading">Case Studies</h2>
        <div className="heading-container">
          <h1>Explore real-life success stories and discover how businesses like yours have leveraged ChirpChat to achieve remarkable results
          </h1>
        </div>
        <div className="row pt-5">
          <div className="col-lg-6">
            <div className="whyBox_2">
              <h3><b>E-commerce Empowerment</b></h3>
              <p>Learn how an e-commerce startup boosted customer engagement and sales conversions by implementing ChirpChat. By integrating the chatbot with their website, they were able to provide instant support, answer product inquiries, and guide customers through the purchasing process. The result? A significant increase in website traffic, higher conversion rates, and improved customer satisfaction.</p>
            </div>
          </div>
          <div className="col-lg-6"><div className="whyBox_2">
            <h3><b>Professional Services Efficiency</b></h3>
            <p>Discover how a professional services firm streamlined their operations and improved client interactions with ChirpChat. By integrating the chatbot with their calendars and document repositories, they were able to automate appointment scheduling, document retrieval, and client communications. As a result, they saved time, reduced administrative overhead, and enhanced client satisfaction.</p>
          </div>
          </div>
          <div className="col-lg-6"><div className="whyBox_2">
            <h3><b>Educational Enhancement</b></h3>
            <p>Explore how an educational institution enhanced student engagement and support services with ChirpChat. By integrating the chatbot with their learning management system and course materials, they were able to provide personalized assistance, answer student queries, and deliver timely notifications. The outcome? Improved student retention rates, higher course completion rates, and positive feedback from students and faculty alike.</p></div>
          </div>
          <div className="col-lg-6">
            <div className="whyBox_2">
              <h3><b>Healthcare Accessibility</b></h3>
              <p> Learn how a healthcare provider improved patient access and satisfaction with ChirpChat. By integrating the chatbot with their appointment scheduling system and medical records database, they were able to streamline patient intake, provide appointment reminders, and offer health-related information. The result? Reduced wait times, increased patient engagement, and improved overall healthcare outcomes.</p>
            </div>
          </div>
        </div>
      </div>
      </section>
      <section className="mt-4 text-center" >
        <div className="container-heading">
          <h1>These are just a few examples of how businesses across various industries have benefited from implementing ChirpChat. Ready to join the ranks of successful case studies? Contact us today to learn more and start your journey towards enhanced customer engagement and business growth</h1>
        </div>
      </section>
    </>
  )
}
export default CaseStudies;