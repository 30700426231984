import React, { useEffect, useState } from 'react';
import { SetpopupReducerData } from '../Store/reducer/PopupReducer';
import { useDispatch, useSelector } from 'react-redux';
import { allApi } from '../apiwrapper/api';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa6";
import { SetUserData } from '../Store/reducer/user';

import { useGoogleLogin } from "@react-oauth/google";
import axios from 'axios';
import apiURL from '../Store/Action/api-url';

const LoginModel = () => {
  const [showModal, setShowModal] = useState(true);
 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);

  const userID=useSelector((state)=>state?.user?.data?._id);


  const dispatch = useDispatch();
  
  const navigate=useNavigate();
  const handleClosepopup = () => {
    dispatch(SetpopupReducerData({ modalType: "", showModal: false }));
  };

  const handleShowSignUp = () => {
    dispatch(
      SetpopupReducerData({
        modalType: "Signup",
        showModal: true,
      })
    );
  
};


  const handleSubmit = async(e) => {
    e.preventDefault();

    try {
      await allApi({
          url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.Signin}`,

          method: "POST",
          body: {
            Email_ID:email,
            Password:password
          },
          headers: {
              'Content-Type': 'application/json'
          },

          formData: false,
      }).then((data) => {
        if(data?.status==="true")
        {
          toast.success(data?.message);
          dispatch(SetUserData(data?.data));

          handleClosepopup();
          navigate('/dashboard');
        }
        toast.error(data?.error);
      });
  } catch (error) {
  } finally {
  }


    console.log('Submitted:', { email, password });
  };


const handleShowForgot=()=>{
  dispatch(SetpopupReducerData({
    modalType: "Forgot",
     showModal: true
  }))
}
const googleLogin = useGoogleLogin({
  onSuccess: (tokenResponse) => setUser(tokenResponse),
});

const fetchData = async () => {
  try {
    const userInfoRes = await axios.get(
      `https://www.googleapis.com/oauth2/v2/userinfo?access_token=${user.access_token}`,
      {
        headers: {
          // Authorization: `Bearer ${user.access_token}`,
          Accept: "application/json",
        },
      }
    );

    const obj = userInfoRes?.data;

    console.log(obj);

    const payload = {
      first_name: obj?.given_name,
      last_name: obj?.family_name,
      Email_ID: obj?.email,
      Image: obj?.picture,
      // GId: obj?.id,
      Method: "GOOGLE",
    };
    console.log(payload, "data");


    const response = await allApi({
      url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.sociallogin}`,

      
      method: "POST",
      body: payload,
      headers: {
        'Content-Type': 'application/json'
    },
      formData: false,
    });

    if (response?.status) {

      toast.success(response.message);
      dispatch(SetUserData(response?.data));
      navigate('/dashboard');


      handleClosepopup();

    }

  }
  catch (err) {
    throw err;
  }
};


useEffect(() => {
  if (user) {
    fetchData();
  }
}, [user]);

  return (
    <>
      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header justify-content-center">
                <h5 className="modal-title">Login </h5>
                <button type="button " className="btn-close position-absolute end-0 me-3" onClick={handleClosepopup} aria-label="Close">
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary text-primary" >Login </button>
                  <button type="button" className="btn btn-secondary text-primary" onClick={handleClosepopup}>Close</button>
                </div>
                </form>
                <button onClick={handleShowForgot}>Forgot password?</button>


                <div className="orWith my-4 text-center">Or Continue with</div>
          <div className="socalLogin my-2">
            <button className="gmBtn" 
            onClick={() => googleLogin()} 
            ><span><FcGoogle /></span> Continue with Google</button>
            <button className="faBtn"><span><FaFacebookF /></span> Continue with Facebook</button>
          

          </div>
          <div className="form-div mt-4 mb-4 text-center">
             <button className="regLnk" onClick={handleShowSignUp}>
            Not a member yet? Sign up
            </button>
            </div>
            </div>
          </div>
         
        </div>
      )}
    </>
  );
};

export default LoginModel;
