import React, { useState } from 'react';
import PopupReducer, { SetpopupReducerData } from '../Store/reducer/PopupReducer';
import { useDispatch, useSelector } from 'react-redux';
import { allApi } from '../apiwrapper/api';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import apiURL from '../Store/Action/api-url';



const OtpModel = () => {
  const [showModal, setShowModal] = useState(true);
 
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
const Email= useSelector((state) => state?.PopupReducer?.modal?.Email);

console.log("hjxahghasjsagj",Email)

  const dispatch = useDispatch();
  
  const navigate=useNavigate();
  const handleClosepopup = () => {
    dispatch(SetpopupReducerData({ modalType: "", showModal: false }));
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return false;
    const newOtp = [...otp];
    console.log(newOtp, "newOtpnewOtp");
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };
 
   const handleVerifyOtp= async(e) => {
    e.preventDefault();
    const otpValue = otp.join("");


    try {
      await allApi({
          url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.verifyotp}`,
          method: "POST",
          body: {
            Email_ID:Email,
            OTP:otpValue,
          },
          headers: {
              'Content-Type': 'application/json'
          },

          formData: false,
      }).then((data) => {
        if(data?.status)
        {

            handleClosepopup();
            dispatch(SetpopupReducerData({
             modalType:"ChangePassword",
             showModal:true,
             email:Email,   

            }))
            toast.success(data?.message);
        }
        toast.error(data?.error);
      });
  } catch (error) {
  } finally {
  }


  };


 
  return (
    <>
      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header justify-content-center">
                <h5 className=" text-center">Please Verify Otp ?</h5>
              
                <button type="button " className="btn-close position-absolute end-0 me-3" onClick={handleClosepopup} aria-label="Close">
                </button>
    
              </div>
              <div>
              <p className='text-center'>
                Please Enter 6 Digit Otp Sent on your Email Address
              </p>
              <br/>
              <p className=" text-center text-primary" ><a>{Email &&Email? Email:""}</a></p>

              </div>
              
              <form 
              onSubmit={handleVerifyOtp}
              >
                <div className="modal-body">
                <div className="d-flex otp" id="otp">
                {otp.map((data, index) => (
                  <input
                    key={index}
                    type="text"
                    className="otpInput"
                    maxLength="1"
                    value={data}
                    onChange={(e) => handleOtpChange(e.target, index)}
                  />
                ))}
              </div>
                
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary text-primary">Verify Otp </button>
                </div>
                </form>
            </div>
          </div>
         
        </div>
      )}
    </>
  );
};

export default OtpModel;
