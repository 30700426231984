import React, { useState, useEffect, useRef } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';

const VoiceRecorder = ({ setAudioBlob }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const streamRef = useRef(null);

    const startRecording = (event) => {
        event.preventDefault();
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                const recorder = new MediaRecorder(stream);
                setMediaRecorder(recorder);
                streamRef.current = stream;

                const chunks = [];
                recorder.ondataavailable = (event) => {
                    chunks.push(event.data);
                };

                recorder.onstop = () => {
                    const audioBlob = new Blob(chunks, { type: 'audio/wav' });
                    setAudioBlob(audioBlob);
                };

                recorder.start();
                setIsRecording(true);
            })
            .catch((err) => console.error('Could not get user media:', err));
    };

    const stopRecording = (event) => {
        event.preventDefault();
        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }
        }
    };

    return (
        <div>
            <button onClick={isRecording ? stopRecording : startRecording}>
                {isRecording ? <PauseCircleOutlineIcon /> : <MicIcon />}
            </button>
        </div>
    );
};

export default VoiceRecorder;
