import React from "react";

const PricePlan = () => {
  return (
    <>
      {/* <section className="whyChosefeatures" id="why">
        <div className="container">
          <h2 className="featuresh2 tabheading heading-container">Pricing Plans</h2>
          <div className="heading-container">
            <h1>Unlock the power of ChirpChat with our flexible pricing plans designed to fit businesses of all sizes</h1>
          </div>
        </div>
      </section> */}

      <div class="hero-basic">
  <div class="hero-basic__bg hero-basic__bg--blue">
    <div class="bg-shapes"></div>
  </div>
  <div class="hero-basic__content">
    <h1>Pricing Plans</h1>
    <p class="customizep">
    Unlock the power of ChirpChat with our flexible pricing plans designed to fit businesses of all sizes
    </p>
    
  </div>
</div>
      <div className="pricecontainer">

        <h2 className="text-center">Select Your Plan</h2>
        <div className="price-row">
          <div className="price-col">
            <p>Basic</p>
            <hr />
            <h3>
              <span>Pricing available upon request</span>
            </h3>
            <p>Ideal for small businesses and startups looking to get started with AI-powered customer engagement. Features include intelligent responses, basic integration with business systems, and essential support. </p>
            <div className="mt-4">
              <button >Consult</button>
            </div>

          </div>



          <div className="price-col" id="best">
            <p id="prem">Pro Plan</p>
            <hr />
            <h3>
              <span>Pricing available upon request</span>
            </h3>
            <p>Perfect for growing businesses and mid-sized enterprises seeking advanced capabilities and customization options. Features include personalized assistance, seamless integration with data sources, proactive engagement, and priority support.</p>
            <div>
              <button className="Consultbutton">Consult</button>
            </div>
          </div>
          <div className="price-col">
            <p>Enterprise Plan</p>
            <hr />
            <h3>
              <span>Pricing available upon request</span>
            </h3>
            <p>Tailored for large enterprises and organizations with complex needs and high-volume interactions. Features include enterprise-grade integration, advanced analytics, customization options, dedicated account management, and 24/7 support. </p>
            <div>
              <button className="Consultbutton">Consult</button>
            </div>
          </div>
        </div>
      </div>








      <br />
      <section className="mt-4" >
        <div className="container heading-container ">
          <p >Contact us today to discuss your specific requirements and find the pricing plan that best suits your business needs.</p>
          <br /><h1><b>Request a Demo</b></h1><br />
          <ul className="customizeul">Experience the power of ChirpChat firsthand with a personalized demo tailored to your business</ul>
          <li className="customizeli ">
            See how our chatbot integrates with your website, documents, calendars, and other data sources.
          </li>
          <li className="customizeli">Explore the features and capabilities of ChirpChat,including intelligent responses, proactive engagement, and advanced analytics.</li>
          <li className="customizeli">
            Learn how ChirpChat can transform your customer engagement, streamline operations, and drive business growth.
          </li><br />
          <p className="text-center mt-5">Ready to take the next step? Fill out the form below to request a demo, and one of our representatives will be in touch to schedule a convenient time for your personalized demo experience.</p>
        </div>
      </section>


      
    </>
  );
};

export default PricePlan;
