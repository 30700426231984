import React, { useEffect, useState } from "react";
import { allApi } from "../apiwrapper/api";
import { useSelector, useStore } from "react-redux";
import { Link, useParams } from "react-router-dom";
import AgentChatBot from "./Chatbot/AgentChatBot";
import io from 'socket.io-client';
const socket = io(process.env.REACT_APP_API_BACKEND_URL);
// const socket = io("http://127.0.0.1:5000")

function ChatbotHumanDash() {

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [chatContainer, setChatContainer] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chatBotTicketsData, setChatBotTicketsData] = useState([]);
  let { session_id, chatbot_id } = useParams();
  const userID = useSelector((state) => state?.user?.data?._id);

  useEffect(() => {
    getChatBotMessages()
  }, [])

  const getChatBotMessages = async () => {
    setIsLoading(true);

    try {
      const response = await allApi({
        url: `${process.env.REACT_APP_API_BACKEND_URL}/get-ticket?user_id=${userID}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        formData: false,
      });

      const data = response?.data;
      setChatBotTicketsData(data);

      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>

      <main>
        <section className="training-materials">

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 border-end">
                <div className="open-tickets bg-light overflow-scroll tickets-scroll">
                  <h3>Open Tickets</h3>


                  {chatBotTicketsData && chatBotTicketsData.map((ticket, index) => {
                    return (
                      <div key={index} className="open-ticket border-top mt-2 p-2 ">
                        <p><strong>Ticket #{ticket?.ticket_id}</strong></p>
                        <p>Status: {ticket.active_status ? "OPEN" : "CLOSED"}</p>
                        <p>Subject: {ticket.ticket_issue}</p>
                        <button className ="btn btn-primary"> 
                         <Link to={`/agentdash/${ticket.session_id}/${ticket.chatbot_id}`}>View Details</Link>
                      </button>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="col-md-4 border-end">
                {session_id && <AgentChatBot session_id={session_id} chatbot_id={chatbot_id} socket={socket} />}
              </div>

              <div className="col-md-4">
                <div className="related-details bg-light">
                  <h3>Related Details</h3>
                  <div className="related-detail">
                    <h3>Ticket #123</h3>
                    <p>Status: Open</p>
                    <p>Priority: High</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
      </main >


    </>
  );
}

export default ChatbotHumanDash;
