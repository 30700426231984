import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from './Components/Store';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider clientId="262237538669-fqb6gvn8fpjsls708digrtah95bmtfk1.apps.googleusercontent.com">

    <React.StrictMode>
      <BrowserRouter>

        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />

          </PersistGate>
        </Provider>
      </BrowserRouter>

    </React.StrictMode>
  </GoogleOAuthProvider>

);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
